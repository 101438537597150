import { useSearchParamsController } from '@retail/hooks';
import { ColumnsType } from 'antd/es/table';
import { get, map } from 'lodash/fp';
import { useMemo } from 'react';
import { PROFIT_AND_LOSS } from 'routes';

import {
  RetailAdPnlDataProjection,
  useSearchRetailAdPnlDataQuery
} from '~/apollo/gql-types';
import { getUserAccessibleCountryCodes } from '~/helpers/user/userAccessibleCountryCodeOptions';
import { useUser } from '~/hooks/useUser';
import { useOverviewColumns } from '~/pages/Overview/hooks/useOverviewColumns';
import {
  getPnlParams,
  PnlParamsModel,
  PNL_BASE_OPTIONS
} from '~/pages/Overview/utils';
import {
  getAdTitle,
  getContractSignedOn,
  getCostItemCount,
  getCostTotalNet,
  getRevenueItemCount,
  getRevenueTotalNet,
  getVehicleDeliveredOn,
  getVin
} from '~/utils/retailAd';

export interface IFormattedOverviewData {
  title: string;
  retailAd: RetailAdPnlDataProjection['retailAd'];
  vin: string;
  contractSignedOn: string;
  vehicleDeliveredOn: string;
  costTotal: string;
  revenueTotal: string;
  costItemCount: number;
  revenueItemCount: number;
}

export interface IUseOverviewPropsResult {
  controller: PnlParamsModel;
  onSubmit: (PnlParamsModel) => null;
  onPageChange: (value, pageSize) => null;
  columns: ColumnsType<RetailAdPnlDataProjection>;
  dataSource: IFormattedOverviewData;
  isLoading: boolean;
}

export const useOverviewProps = () => {
  const { accessParameters } = useUser();
  const userAccessibleCountryCodes = useMemo(
    () => getUserAccessibleCountryCodes(accessParameters),
    [accessParameters]
  );
  const columns = useOverviewColumns();
  const { controller, onPageChange, updateController } =
    useSearchParamsController<PnlParamsModel>(
      ({ queryString, language, navigate }) =>
        navigate(`${PROFIT_AND_LOSS.LINK({ language })}${queryString}`)
    );
  const { data, loading: isLoading } = useSearchRetailAdPnlDataQuery({
    ...PNL_BASE_OPTIONS,
    variables: {
      search: getPnlParams(controller, userAccessibleCountryCodes)
    },
    onCompleted: (data) =>
      updateController({
        totalPages: data?.data?.totalEntityCount
      })
  });

  const dataSource = useMemo(() => {
    const rawDataSource = data?.data?.entities ?? [];

    if (!rawDataSource.length) {
      return [];
    }

    return map((item) => ({
      id: get(['retailAd', 'id'])(item),
      title: getAdTitle(item),
      retailAd: get(['retailAd'])(item),
      vin: getVin(item),
      contractSignedOn: getContractSignedOn(item),
      vehicleDeliveredOn: getVehicleDeliveredOn(item),
      costTotal: getCostTotalNet(item),
      revenueTotal: getRevenueTotalNet(item),
      costItemCount: getCostItemCount(item),
      revenueItemCount: getRevenueItemCount(item)
    }))(rawDataSource);
  }, [data]);

  return useMemo(
    () => ({
      dataSource,
      columns,
      isLoading,
      controller,
      onPageChange,
      onSubmit: updateController
    }),
    [columns, controller, dataSource, isLoading, onPageChange, updateController]
  );
};
