import { useMemo } from 'react';

import { RetailAdPnlDataProjection } from '~/apollo/gql-types';
import {
  getAdCountry,
  getRetailAdId,
  getAdTitle,
  getVin,
  getStockNumber,
  getFirstPublishedOn,
  getContractSignedOn,
  getVehicleDeliveredOn,
  getCostTotalNet,
  getCostTotalGross,
  getRevenueTotalNet,
  getRevenueTotalGross,
  getCostItemCount,
  getRevenueItemCount
} from '~/utils/retailAd';

export type TUseItemFormattedDataParams = RetailAdPnlDataProjection;

export const useItemFormattedData = (data: TUseItemFormattedDataParams) =>
  useMemo(() => {
    return data
      ? {
          retailCountry: getAdCountry(data),
          adId: getRetailAdId(data),
          title: getAdTitle(data),
          country: getAdCountry(data),
          stockNumber: getStockNumber(data),
          vin: getVin(data),
          firstPublishedOn: getFirstPublishedOn(data),
          contractSignedOn: getContractSignedOn(data),
          vehicleDeliveredOn: getVehicleDeliveredOn(data),
          costNetTotal: getCostTotalNet(data),
          costGrossTotal: getCostTotalGross(data),
          revenueNetTotal: getRevenueTotalNet(data),
          revenueGrossTotal: getRevenueTotalGross(data),
          costItemCount: getCostItemCount(data),
          revenueItemCount: getRevenueItemCount(data)
        }
      : {};
  }, [data]);
