import { isString } from 'lodash/fp';
import { isValid, parseISO } from 'date-fns';

import { isSafari, isMobileSafari } from '../browser';

/**
 * Do not change unnecessarily, those functions affects performance
 */
const defaultParseDate = (date) => (isString(date) ? Date.parse(date) : date);

const safariParseDate = (rawDate) => {
  if (!isString(rawDate)) {
    return rawDate;
  }
  const date = parseISO(rawDate);
  return isValid(date) ? +date : NaN;
};

export const createParseDate = () =>
  isSafari || isMobileSafari ? safariParseDate : defaultParseDate;
