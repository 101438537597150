import { useCallback, useState } from 'react';

import { PAGE_SIZE } from '~/constants/common';

import { IChangeLogParamsModel } from '../utils';

export const controllerInitialData: IChangeLogParamsModel = {
  page: 1,
  totalPages: 0,
  pageSize: PAGE_SIZE
};

export function useChangelogController() {
  const [controller, setController] = useState({
    ...controllerInitialData
  });

  const updateController = useCallback(
    (data: IChangeLogParamsModel) => {
      const params = { ...controller, ...data };
      setController(params);
    },
    [controller]
  );

  const onPageChange = useCallback(
    (value, pageSize) => {
      updateController({
        page: pageSize !== controller.pageSize ? 1 : value,
        pageSize
      });
    },
    [controller.pageSize, updateController]
  );

  return {
    controller,
    updateController,
    onPageChange
  };
}
