import { flow } from 'lodash/fp';

import prepareFormattedOptions from '../prepareFormattedOptions';

import { getVatRates } from './values';

const PERCENT_100 = 100;
const VAT_RATE_PRECISION = 2;

export const formatVatRateLabel = (value) =>
  `${((value || 0) * PERCENT_100).toFixed(VAT_RATE_PRECISION)}%`;

export const getVatRateOptions = flow(
  getVatRates,
  prepareFormattedOptions(formatVatRateLabel)
);
