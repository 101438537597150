import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  RetailAdPnlDataProjection,
  useRetailAdPnlDataQuery
} from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

export interface IUseRetailAdItemDataResult {
  data?: RetailAdPnlDataProjection;
  isLoading: boolean;
}

export const useItemData = (): IUseRetailAdItemDataResult => {
  const { id } = useParams();
  const { data, loading: isLoading } = useRetailAdPnlDataQuery({
    ...REQUEST_BASE_OPTIONS,
    variables: {
      id
    }
  });

  return useMemo(
    () => ({
      isLoading,
      data: data?.data as RetailAdPnlDataProjection
    }),
    [data, isLoading]
  );
};
