import { map, identity, isString, isFunction } from 'lodash/fp';

export default (...args) => {
  let [formatLabel, prop] = args;

  if (isString(formatLabel)) {
    prop = formatLabel;
  }

  if (!isFunction(formatLabel)) {
    formatLabel = identity;
  }

  return map((item) => {
    let value = item;

    if (prop) {
      value = item[prop];
    }

    return {
      value,
      label: formatLabel(value)
    };
  });
};
