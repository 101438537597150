import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export function useLogDetails() {
  const {
    adId,
    userId,
    groupId,
    roleId,
    orderId,
    claimId,
    modal,
    packageId,
    currentStatus,
    leadId,
    id,
    retailAdId,
    refurbishmentType
  } = useParams();

  return useMemo(
    () => ({
      adId,
      userId,
      groupId,
      roleId,
      orderId,
      claimId,
      modal,
      packageId,
      currentStatus,
      leadId,
      id,
      retailAdId,
      refurbishmentType
    }),
    [
      adId,
      claimId,
      currentStatus,
      groupId,
      id,
      leadId,
      modal,
      orderId,
      packageId,
      refurbishmentType,
      retailAdId,
      roleId,
      userId
    ]
  );
}
