import { Spin } from 'antd';
import { debounce } from 'lodash/fp';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  AntOptionProps,
  SelectControlled,
  SelectControlledProps
} from './SelectControlled';

export interface SelectControlledAsyncProps<T>
  extends Omit<SelectControlledProps<T>, 'options'> {
  wait?: number;
  defaultOptions?: AntOptionProps[];
  fetchOptions: (x: string) => Promise<any>;
}

export function SelectControlledAsync<T>({
  wait = 400,
  defaultOptions,
  onSearch,
  fetchOptions,
  ...props
}: SelectControlledAsyncProps<T>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<AntOptionProps[]>([]);

  const fetchRef = useRef(0);

  const debouncedFetchOptions = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(wait)(loadOptions);
  }, [fetchOptions, wait]);

  const handleSearch = useCallback(
    (value) => {
      onSearch?.(value);
      debouncedFetchOptions(value);
    },
    [onSearch, debouncedFetchOptions]
  );

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  return (
    <SelectControlled
      options={options}
      filterOption={false}
      onSearch={handleSearch}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
    />
  );
}
