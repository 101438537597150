import { PlusOutlined } from '@ant-design/icons';
import { useSearchParamsController } from '@retail/hooks';
import { Button, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useSearchPnlPartnersQuery } from '~/apollo/gql-types';
import { MdFinanceIcon } from '~/components/Icons/MdFinanceIcon';
import { Layout, LayoutBody, LayoutHeader } from '~/components/Layout';
import { PaginationRow } from '~/components/PaginationRow';
import {
  PermissionChecker,
  withPermissions
} from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import {
  PROFIT_AND_LOSS_PARTNERS,
  PROFIT_AND_LOSS_PARTNERS_CREATE
} from '../../routes';
import { NavigationTabs } from '../NavigationTabs';

import { usePartnersColumns } from './hooks/usePartnersColumns';
import { SearchForm } from './SearchForm';
import cn from './styles.less';
import {
  getPartnersParams,
  PartnersParamsModel,
  PARTNERS_BASE_OPTIONS
} from './utils';

export const Partners = withPermissions(
  PERMISSIONS.QUERY_SEARCH_PNL_PARTNERS,
  DOMAINS.PARTNERS_PNL_MANAGEMENT
)(() => {
  const { t } = useTranslation();
  const { language } = useParams();
  const columns = usePartnersColumns();
  const { controller, onPageChange, updateController } =
    useSearchParamsController<PartnersParamsModel>(
      ({ queryString, language, navigate }) =>
        navigate(`${PROFIT_AND_LOSS_PARTNERS.LINK({ language })}${queryString}`)
    );

  const { data, loading: isLoading } = useSearchPnlPartnersQuery({
    ...PARTNERS_BASE_OPTIONS,
    variables: {
      search: getPartnersParams(controller)
    },
    onCompleted: (data) =>
      updateController({
        totalPages: data?.data?.totalEntityCount
      })
  });
  const dataSource = data?.data?.entities ?? [];

  return (
    <Layout>
      <LayoutHeader
        title={t('bo.profitAndLoss.partners.title')}
        icon={<MdFinanceIcon />}
      >
        <PermissionChecker
          domain={DOMAINS.PARTNERS_PNL_MANAGEMENT}
          allow={PERMISSIONS.MUTATION_CREATE_PNL_PARTNER}
        >
          <Button
            type="primary"
            data-qa-selector-name="addPartnerButton"
            icon={<PlusOutlined />}
            href={PROFIT_AND_LOSS_PARTNERS_CREATE.LINK({ language })}
          >
            <span>{t('bo.profitAndLoss.partners.buttons.addPartner')}</span>
          </Button>
        </PermissionChecker>
      </LayoutHeader>

      <NavigationTabs />

      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <SearchForm
            country={controller.country}
            partnerId={controller.partnerId}
            partnerVatId={controller.partnerVatId}
            partnerName={controller.partnerName}
            sortBy={controller.sortBy}
            isLoading={isLoading}
            onSubmit={updateController}
          />
          <PaginationRow
            showTotalResults
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
            isLoading={isLoading}
          />
          <Table
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            loading={isLoading}
            pagination={false}
            scroll={{ x: true }}
          />
          <PaginationRow
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
            isLoading={isLoading}
          />
        </Space>
      </LayoutBody>
    </Layout>
  );
});
