// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VbnXuLA8EMRwXV7SINUf {\n  width: 100%;\n  max-width: 720px;\n  margin: 0 auto;\n}\n.nYQAiByIRTT9FkNVcrNB {\n  width: 100%;\n}\n.Ov_DVoP6eKy4Q7vaquSk {\n  display: flex;\n  align-items: center;\n  grid-gap: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/PartnersCreate/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AACF;AAEA;EACE,WAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AADF","sourcesContent":[".wrapper {\n  width: 100%;\n  max-width: 720px;\n  margin: 0 auto;\n}\n\n.fullWidth {\n  width: 100%;\n}\n\n.buttonHolder {\n  display: flex;\n  align-items: center;\n  grid-gap: 1.5rem;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "VbnXuLA8EMRwXV7SINUf",
	"fullWidth": "nYQAiByIRTT9FkNVcrNB",
	"buttonHolder": "Ov_DVoP6eKy4Q7vaquSk"
};
export default ___CSS_LOADER_EXPORT___;
