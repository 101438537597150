import {
  getFormatInputFromMinorUnits,
  getParseInputToMinorUnits
} from '@retail/currency';
import { Card, Col, Form, Row } from 'antd';
import { isFuture } from 'date-fns';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PnlPartnerProjection } from '~/apollo/gql-types';
import { DatePickerControlled } from '~/components/Form/DatePickerControlled';
import { InputControlled } from '~/components/Form/InputControlled';
import { SelectControlled } from '~/components/Form/SelectControlled';
import { PNL_ITEM_TYPES } from '~/constants/pnl';

import { useFormOptions } from '../hooks/useFormOptions';
import { useSyncFormValues } from '../hooks/useSyncFormValues';
import { TFormModalValues } from '../utils';

import { PartnersSelectAsync } from './PartnersSelectAsync';
import cn from './styles.less';

interface IFormProps {
  initialValues?: TFormModalValues;
  partner: PnlPartnerProjection;
  onSubmit: (values: Partial<TFormModalValues>) => void;
}

export const FormFields = ({
  onSubmit,
  partner,
  initialValues
}: IFormProps) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext<TFormModalValues>();
  const [type, category, currencyCode] = useWatch({
    control,
    name: ['type', 'category', 'currencyCode']
  });

  const { options, isLoading: isLoadingOptions } = useFormOptions(
    {
      type,
      category
    },
    partner
  );

  const {
    handleCategoryChange,
    handleTypeChange,
    handleNetAmountChange,
    handleVatRateChange
  } = useSyncFormValues({ initialValues });

  return (
    <Card bordered={false} loading={isLoadingOptions} size="small">
      <Form
        onFinish={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        className={cn.form}
        data-qa-selector-name="pnlItem-form"
      >
        <Row gutter={24}>
          <Col sm={6}>
            <InputControlled
              disabled
              qaSelector="pnlItem-retailCountry"
              label={t(
                'bo.profitAndLoss.item.addItemForm.fields.retailCountry'
              )}
              controllerProps={{
                name: 'retailCountry',
                control
              }}
            />
          </Col>
          <Col sm={6}>
            <InputControlled
              disabled
              qaSelector="pnlItem-stockNumber"
              label={t('bo.profitAndLoss.item.addItemForm.fields.stockNumber')}
              controllerProps={{
                name: 'stockNumber',
                control
              }}
            />
          </Col>
          <Col sm={12}>
            <InputControlled
              disabled
              qaSelector="pnlItem-vin"
              label={t('bo.profitAndLoss.item.addItemForm.fields.vin')}
              controllerProps={{
                name: 'vin',
                control
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={12}>
            <SelectControlled
              required
              showSearch={false}
              allowClear={false}
              options={options.itemTypes}
              qaSelector="pnlItem-type"
              label={t('bo.profitAndLoss.item.addItemForm.fields.itemType')}
              onChange={handleTypeChange}
              controllerProps={{
                name: 'type',
                control
              }}
            />
          </Col>
          <Col sm={12}>
            <SelectControlled
              required
              showSearch
              allowClear={false}
              disabled={!type}
              qaSelector="pnlItem-category"
              options={options.categories}
              label={t('bo.profitAndLoss.item.addItemForm.fields.category')}
              onChange={handleCategoryChange}
              controllerProps={{
                name: 'category',
                control
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={12}>
            <SelectControlled
              required
              showSearch
              allowClear={false}
              disabled={!type || !category}
              options={options.names}
              qaSelector="pnlItem-pnlItemTypeId"
              label={t('bo.profitAndLoss.item.addItemForm.fields.itemName')}
              controllerProps={{
                name: 'pnlItemTypeId',
                control
              }}
            />
          </Col>
          <Col sm={12}>
            <InputControlled
              required
              qaSelector="pnlItem-entityChargedName"
              label={t(
                'bo.profitAndLoss.item.addItemForm.fields.entityChargedName'
              )}
              controllerProps={{
                name: 'entityChargedName',
                control
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={6}>
            <SelectControlled
              required
              allowClear={false}
              showSearch={false}
              options={options.currencies}
              qaSelector="pnlItem-currencyCode"
              label={t('bo.profitAndLoss.item.addItemForm.fields.currency')}
              controllerProps={{
                name: 'currencyCode',
                control
              }}
            />
          </Col>
          <Col sm={6}>
            <InputControlled
              required
              format={getFormatInputFromMinorUnits(currencyCode)}
              parse={getParseInputToMinorUnits(currencyCode)}
              type="number"
              min="0"
              label={t('bo.profitAndLoss.item.addItemForm.fields.netAmount')}
              qaSelector="pnlItem-netAmountMinorUnits"
              onChange={(value) => handleNetAmountChange(value)}
              controllerProps={{
                name: 'netAmountMinorUnits',
                control
              }}
            />
          </Col>
          <Col sm={12}>
            <SelectControlled
              required
              options={options.vatRates}
              qaSelector="pnlItem-vatRate"
              label={t('bo.profitAndLoss.item.addItemForm.fields.vatRate')}
              placeholder={t(
                'bo.profitAndLoss.item.addItemForm.fields.vatRate'
              )}
              onChange={handleVatRateChange}
              controllerProps={{
                name: 'vatRate',
                control
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={12}>
            <InputControlled
              disabled
              format={getFormatInputFromMinorUnits(currencyCode)}
              type="number"
              qaSelector="pnlItem-grossAmountMinorUnits"
              label={t('bo.profitAndLoss.item.addItemForm.fields.grossAmount')}
              controllerProps={{
                name: 'grossAmountMinorUnits',
                control
              }}
            />
          </Col>
          <Col sm={12}>
            <InputControlled
              disabled
              format={getFormatInputFromMinorUnits(currencyCode)}
              type="number"
              qaSelector="pnlItem-vatAmountMinorUnits"
              label={t('bo.profitAndLoss.item.addItemForm.fields.vatAmount')}
              controllerProps={{
                name: 'vatAmountMinorUnits',
                control
              }}
            />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col sm={6}>
            <SelectControlled
              required
              allowClear={false}
              showSearch={false}
              options={options.invoiceTypes}
              qaSelector="pnlItem-pnlInvoiceType"
              label={t('bo.profitAndLoss.item.addItemForm.fields.invoiceType')}
              controllerProps={{
                name: 'pnlInvoiceType',
                control
              }}
            />
          </Col>
          <Col sm={6}>
            <InputControlled
              required
              qaSelector="pnlItem-invoiceNumber"
              label={t(
                'bo.profitAndLoss.item.addItemForm.fields.invoiceNumber'
              )}
              controllerProps={{
                name: 'invoiceNumber',
                control
              }}
            />
          </Col>
          <Col sm={12}>
            <DatePickerControlled
              required
              dateFormat="DD/MM/YYYY"
              disabledDate={isFuture}
              qaSelector="pnlItem-invoiceDate"
              label={t('bo.profitAndLoss.item.addItemForm.fields.invoiceDate')}
              controllerProps={{
                name: 'invoiceDate',
                control
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={12}>
            <InputControlled
              qaSelector="pnlItem-serviceId"
              label={t('bo.profitAndLoss.item.addItemForm.fields.serviceID')}
              controllerProps={{
                name: 'serviceId',
                control
              }}
            />
          </Col>
          <Col sm={12}>
            <DatePickerControlled
              required
              dateFormat="DD/MM/YYYY"
              disabledDate={isFuture}
              qaSelector="pnlItem-serviceEndDate"
              label={t(
                'bo.profitAndLoss.item.addItemForm.fields.serviceEndDate'
              )}
              controllerProps={{
                name: 'serviceEndDate',
                control
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={12}>
            <PartnersSelectAsync
              showSearch
              required={type === PNL_ITEM_TYPES.COST}
              allowClear={type === PNL_ITEM_TYPES.REVENUE}
              defaultOptions={options.partners}
              qaSelector="pnlItem-partnerId"
              label={t('bo.profitAndLoss.item.addItemForm.fields.costPartner')}
              controllerProps={{
                name: 'partnerId',
                control
              }}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
