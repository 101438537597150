import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ModalProps, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { flow, get, size } from 'lodash/fp';
import { makeVar, useReactiveVar } from '@apollo/client';

import {
  ProfitAndLossImportCreateProjectionInput,
  useImportProfitAndLossItemsMutation
} from '~/apollo/gql-types';
import { PNL_IMPORT_BASE_OPTIONS } from '~/pages/Import/utils';
import { IUseModal } from '~/hooks/useModal';

import { useUploadCredentials } from './useUploadCredentials';
import { useUploadToS3 } from './useUploadToS3';

const isLoadingVar = makeVar(false);

export interface IUseUploadFormProps {
  isOpenModal: boolean;
  closeModal: IUseModal['closeModal'];
}

export const useUploadFormProps = ({
  isOpenModal,
  closeModal
}: IUseUploadFormProps) => {
  const { t } = useTranslation();
  const getUploadCredentials = useUploadCredentials();
  const uploadToS3 = useUploadToS3();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = useForm<ProfitAndLossImportCreateProjectionInput>();
  const [importItems] = useImportProfitAndLossItemsMutation({
    ...PNL_IMPORT_BASE_OPTIONS,
    refetchQueries: ['SearchRetailDataImport']
  });

  const onSubmit = useCallback(
    async ({ files }) => {
      isLoadingVar(true);
      const credentialsData = await getUploadCredentials(files);
      const entities = await uploadToS3(
        files,
        get(['data', 'data'])(credentialsData)
      );
      const importItemsResult = await importItems({
        variables: {
          entities
        }
      });

      const length = flow(get(['data', 'data']), size)(importItemsResult);

      notification.success({
        message: t('bo.profitAndLoss.import.modal.uploadCSV.success', {
          length
        })
      });

      isLoadingVar(false);
      closeModal();
    },
    [closeModal, getUploadCredentials, importItems, t, uploadToS3]
  );

  const modalProps = useMemo(
    (): Partial<ModalProps> => ({
      visible: isOpenModal,
      onCancel: closeModal,
      cancelButtonProps: {
        onClick: closeModal,
        className: 'modal-cancelButton',
        children: t('bo.profitAndLoss.buttons.cancel')
      },
      okButtonProps: {
        htmlType: 'submit',
        disabled: isSubmitting,
        onClick: handleSubmit(onSubmit),
        className: 'modal-submitButton',
        children: t('bo.profitAndLoss.buttons.save')
      }
    }),
    [closeModal, handleSubmit, isOpenModal, isSubmitting, onSubmit, t]
  );

  useEffect(() => isOpenModal && reset(), [isOpenModal, reset]);

  const isLoading = useReactiveVar(isLoadingVar);

  return useMemo(
    () => ({
      control,
      modalProps,
      isLoading,
      handleSubmit: handleSubmit(onSubmit)
    }),
    [control, handleSubmit, isLoading, modalProps, onSubmit]
  );
};
