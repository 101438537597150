import { SearchOutlined } from '@ant-design/icons';
import {
  createFilter,
  createFilterPayload,
  Filter,
  FilterType
} from '@retail/gql-utils';
import { useCallback } from 'react';

import { useSearchPnlPartnersLazyQuery } from '~/apollo/gql-types';
import {
  SelectControlledAsync,
  SelectControlledAsyncProps
} from '~/components/Form/SelectControlledAsync';
import { PARTNERS_BASE_OPTIONS } from '~/pages/Partners/utils';
import { mapPartnerToOption } from '~/utils/partner';

import { TFormModalValues } from '../utils';

interface Props<T> extends Omit<SelectControlledAsyncProps<T>, 'fetchOptions'> {
  pageSize?: number;
}

export function PartnersSelectAsync({
  pageSize = 10,
  ...props
}: Props<TFormModalValues>) {
  const [lazyFetch] = useSearchPnlPartnersLazyQuery(PARTNERS_BASE_OPTIONS);

  const fetchOptionsAsync = useCallback(
    async (value: string) => {
      const filters: Filter[] = [
        createFilter('deleted', FilterType.EQUAL, false)
      ];
      let filter: Filter;

      if (value) {
        filters.push(
          createFilter(null, FilterType.OR, [
            createFilter('partnerName', FilterType.LIKE, value),
            createFilter('partnerId', FilterType.LIKE, value)
          ])
        );
      } else {
        return [];
      }

      if (filters.length) {
        filter =
          filters.length > 1
            ? createFilter(null, FilterType.AND, filters)
            : filters[0];
      }

      const { data } = await lazyFetch({
        variables: {
          search: createFilterPayload({
            filter,
            pageSize,
            page: 0
          })
        }
      });

      return data?.data?.entities.map(mapPartnerToOption) ?? [];
    },
    [lazyFetch, pageSize]
  );

  return (
    <SelectControlledAsync
      {...props}
      suffixIcon={<SearchOutlined />}
      fetchOptions={fetchOptionsAsync}
    />
  );
}
