import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { PermissionChecker } from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import cn from './styles.less';

export interface IActionsProps {
  id: string;
  onEdit: () => void;
  onDelete: (id: string) => void;
}

export function Actions({ id, onEdit, onDelete }: IActionsProps) {
  return (
    <span className={cn.actionsHolder}>
      <PermissionChecker
        domain={DOMAINS.PNL_MANAGEMENT}
        allow={PERMISSIONS.MUTATION_UPDATE_PNL_ITEM}
      >
        <Button
          size="small"
          type="primary"
          data-qa-selector-name="edit"
          icon={<EditOutlined />}
          onClick={onEdit}
        />
      </PermissionChecker>
      <PermissionChecker
        domain={DOMAINS.PNL_MANAGEMENT}
        allow={PERMISSIONS.MUTATION_DELETE_PNL_ITEM}
      >
        <Button
          size="small"
          type="primary"
          data-qa-selector-name="delete"
          danger
          icon={<DeleteOutlined />}
          onClick={() => onDelete(id)}
        />
      </PermissionChecker>
    </span>
  );
}
