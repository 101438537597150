import { createContext, useContext } from 'react';

import { IUseModal } from '~/hooks/useModal';

export type TModalProvider<Data> = IUseModal<Data>;

const context = createContext(null);

export const { Provider } = context;

export const useModalContext = <Data>(): TModalProvider<Data> =>
  useContext(context);
