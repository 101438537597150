// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XwiaJgFka1AOSVsFmgQL .antd-4-21-7-form-item-label {\n  padding-bottom: 0 !important;\n}\n.XwiaJgFka1AOSVsFmgQL .antd-4-21-7-form-item {\n  display: inline-block;\n  margin-bottom: 0;\n  margin-right: 1.5rem;\n}\n.XwiaJgFka1AOSVsFmgQL .S6nEAWkMo3N4hNiZ41W7 {\n  width: 400px;\n}\n.XwiaJgFka1AOSVsFmgQL .n1cuUir9T6Yw9C54ZMa7 {\n  display: inline-block;\n  vertical-align: bottom;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Overview/SearchForm/style.less"],"names":[],"mappings":"AAKM;EACE,4BAAA;AAJR;AAOM;EACE,qBAAA;EACA,gBAAA;EACA,oBAAA;AALR;AALA;EAgBI,YAAA;AARJ;AARA;EAoBI,qBAAA;EACA,sBAAA;AATJ","sourcesContent":["@import '../../../styles/constants.less';\n\n.form {\n  :global {\n    .@{antd-version} {\n      &-form-item-label {\n        padding-bottom: 0 !important;\n      }\n\n      &-form-item {\n        display: inline-block;\n        margin-bottom: 0;\n        margin-right: 1.5rem;\n      }\n    }\n  }\n\n  .stockNumber {\n    width: 400px;\n  }\n\n  .submit {\n    display: inline-block;\n    vertical-align: bottom;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "XwiaJgFka1AOSVsFmgQL",
	"stockNumber": "S6nEAWkMo3N4hNiZ41W7",
	"submit": "n1cuUir9T6Yw9C54ZMa7"
};
export default ___CSS_LOADER_EXPORT___;
