import { getCurrencyCodeByCountryCode } from '@retail/currency';
import {
  at,
  flow,
  get,
  isEqual,
  join,
  map,
  reject,
  size,
  trim
} from 'lodash/fp';

import { PnlInvoiceType } from '~/apollo/gql-types';
import { getFormattedCurrencySum } from '~/helpers/currency';
import { formatDate, formatDateTime } from '~/helpers/date';

const makeNegativeCreditNoteAmount = (amountType) => (item) => {
  if (isEqual(get(['pnlInvoiceType'])(item), PnlInvoiceType.CreditNote)) {
    return {
      ...item[amountType],
      amountMinorUnits: item[amountType].amountMinorUnits * -1
    };
  }

  return item[amountType];
};

export const getAdTitle = flow(
  get(['classifiedAd', 'vehicle']),
  flow(at(['manufacturer', 'model']), join(' '), trim)
);

export const getRetailAd = get(['retailAd']);

export const getAdCountry = flow(getRetailAd, get(['country']));

export const getAdId = flow(getRetailAd, get(['id']));

export const getRetailAdId = flow(getRetailAd, get(['adId']));

export const getAdCurrency = flow(getAdCountry, getCurrencyCodeByCountryCode);

export const getVin = get(['classifiedAd', 'vehicle', 'vin']);

export const getStockNumber = get(['retailAd', 'stockNumber']);

export const getFirstPublishedOn = flow(
  get(['classifiedAd', 'firstPublishedAt']),
  formatDateTime
);

export const getContractSignedOn = flow(
  get(['order', 'contractSignedOn']),
  formatDateTime
);

export const getVehicleDeliveredOn = flow(
  get(['order', 'vehicleDeliveredOn']),
  formatDate
);

export const getCostItems = get(['costItems']);
export const getRevenueItems = get(['revenueItems']);

export const getCostTotalGross = (item) => {
  const currency = getAdCurrency(item);
  const prices = flow(
    getCostItems,
    reject(flow(get(['pnlInvoiceType']), isEqual(PnlInvoiceType.Estimate))),
    map(makeNegativeCreditNoteAmount('grossAmount'))
  )(item);

  return getFormattedCurrencySum(currency, prices);
};

export const getCostTotalNet = (item) => {
  const currency = getAdCurrency(item);
  const prices = flow(
    getCostItems,
    reject(flow(get(['pnlInvoiceType']), isEqual(PnlInvoiceType.Estimate))),
    map(get(['netAmount']))
  )(item);

  return getFormattedCurrencySum(currency, prices);
};

export const getCostTotalNetCount = flow(
  getCostItems,
  reject(flow(get(['pnlInvoiceType']), isEqual(PnlInvoiceType.Estimate)))
);

export const getRevenueTotalGross = (item) => {
  const currency = getAdCurrency(item);
  const prices = flow(getRevenueItems, map(get(['grossAmount'])))(item);

  return getFormattedCurrencySum(currency, prices);
};

export const getRevenueTotalNet = (item) => {
  const currency = getAdCurrency(item);
  const prices = flow(getRevenueItems, map(get(['netAmount'])))(item);

  return getFormattedCurrencySum(currency, prices);
};

export const getCostItemCount = flow(getCostTotalNetCount, size);
export const getRevenueItemCount = flow(getRevenueItems, size);
