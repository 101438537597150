import {
  PnlItemCreateProjectionInput,
  PnlItemProjection,
  PnlItemUpdateProjectionInput
} from '~/apollo/gql-types';
import { PNL_ITEM_TYPES } from '~/constants/pnl';

export interface IInnerFormValues
  extends Pick<PnlItemProjection, 'netAmount' | 'vatAmount'> {
  category: string;
  type: PNL_ITEM_TYPES;
}

export type TFormValues =
  | IInnerFormValues
  | PnlItemCreateProjectionInput
  | PnlItemUpdateProjectionInput;

export type TFormModalValues = Pick<PnlItemProjection, 'id' | 'retailCountry'> &
  TFormValues;

export const OMIT_FIELDS_PNL_ITEM_CREATE = ['id', 'type', 'category'];
export const OMIT_FIELDS_PNL_ITEM_UPDATE = [
  'id',
  'type',
  'category',
  'retailAdId',
  'retailCountry',
  'stockNumber',
  'vin'
];
