import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AntOptionProps, SelectControlled } from './SelectControlled';

type Props<T> = PropsOf<typeof SelectControlled<T>>;

export function SelectCreatable<T>({
  options: defaultOptions,
  qaSelector,
  ...rest
}: Props<T>) {
  const { t } = useTranslation();
  const [options, setOptions] = useState<AntOptionProps[]>([]);
  const [value, setValue] = useState('');

  function onValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function addItem(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    setOptions((options) =>
      options.some((x) => x.value === value)
        ? options
        : [{ label: value, value: value } as AntOptionProps].concat(options)
    );
    setValue('');
  }

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  return (
    <SelectControlled
      {...rest}
      data-qa-selector-name={qaSelector}
      dropdownClassName={`${qaSelector}-dropdown`}
      options={options}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <Space align="center" style={{ padding: '4px 8px 4px' }}>
            <Input
              placeholder={t(
                'bo.profitAndLoss.itemTypes.placeholder.pleaseEnterItem'
              )}
              value={value}
              onChange={onValueChange}
            />
            <Typography.Link
              disabled={!value}
              onClick={addItem}
              style={{ whiteSpace: 'nowrap' }}
            >
              <PlusOutlined /> {t('bo.profitAndLoss.itemTypes.buttons.addItem')}
            </Typography.Link>
          </Space>
        </>
      )}
    />
  );
}
