// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cRCRDUByHN6S_U12MFG1 {\n  width: 100%;\n  max-width: 1140px;\n  margin: 0 auto;\n}\n.a85AyXIJ9pcAfUQPBVIU {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/RetailAdItem/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;AACF;AAEA;EACE,WAAA;AAAF","sourcesContent":[".wrapper {\n  width: 100%;\n  max-width: 1140px;\n  margin: 0 auto;\n}\n\n.fullWidth {\n  width: 100%;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "cRCRDUByHN6S_U12MFG1",
	"fullWidth": "a85AyXIJ9pcAfUQPBVIU"
};
export default ___CSS_LOADER_EXPORT___;
