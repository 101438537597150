import { flow, values, sortBy } from 'lodash/fp';
import {
  COUNTRIES_VALUES,
  ADDRESS_COUNTRIES_VALUES
} from '@retail/i18n/constants';

import { toLocalizedCountry } from '~/utils/i18n';

import prepareFormattedOptions from '../prepareFormattedOptions';

const prepareCountryOptions = flow(
  prepareFormattedOptions(toLocalizedCountry),
  sortBy('label')
);

export const countryAddressOptions = flow(
  values,
  prepareCountryOptions
)(ADDRESS_COUNTRIES_VALUES);
export const countriesOptions = flow(
  values,
  prepareCountryOptions
)(COUNTRIES_VALUES);
