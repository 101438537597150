// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hzuzPEzu4rI3Hlq4DsQE {\n  display: flex;\n  padding: 5px 0 !important;\n}\n.slstgYaHvTNn1TOimBa5 {\n  font-size: 14px;\n  margin-right: 10px;\n  color: rgba(0, 0, 0, 0.45);\n}\n.CChi78NPLsgVT8GuL0hC {\n  font-size: 14px;\n  margin-left: 10px;\n  color: rgba(0, 0, 0, 0.45);\n}\n.MOL16kAMSmrW9E0F_ldA {\n  text-align: left;\n  flex-grow: 1;\n  overflow: hidden;\n  width: 100%;\n  text-overflow: ellipsis;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Form/DropzoneControlled/FileList/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;AAEA;EACE,eAAA;EACA,kBAAA;EACA,0BAAA;AAAF;AAGA;EACE,eAAA;EACA,iBAAA;EACA,0BAAA;AADF;AAIA;EACE,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,uBAAA;AAFF","sourcesContent":[".listItem {\n  display: flex;\n  padding: 5px 0 !important;\n}\n\n.listItemClipIcon {\n  font-size: 14px;\n  margin-right: 10px;\n  color: rgba(0, 0, 0, 0.45);\n}\n\n.listItemDeleteIcon {\n  font-size: 14px;\n  margin-left: 10px;\n  color: rgba(0, 0, 0, 0.45);\n}\n\n.listItemContent {\n  text-align: left;\n  flex-grow: 1;\n  overflow: hidden;\n  width: 100%;\n  text-overflow: ellipsis;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": "hzuzPEzu4rI3Hlq4DsQE",
	"listItemClipIcon": "slstgYaHvTNn1TOimBa5",
	"listItemDeleteIcon": "CChi78NPLsgVT8GuL0hC",
	"listItemContent": "MOL16kAMSmrW9E0F_ldA"
};
export default ___CSS_LOADER_EXPORT___;
