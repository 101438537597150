import { useCallback } from 'react';

import { LogEventType } from '~/constants/logEventTypes';
import { useSaveVisitingLogMutation } from '~/apollo/gql-types';

import { useLogDetails } from './useLogDetails';

export function useDocumentViewLogging() {
  const logDetails = useLogDetails();
  const [saveLog] = useSaveVisitingLogMutation();

  return useCallback(
    (targetUrl) => {
      saveLog({
        variables: {
          model: {
            eventType: LogEventType.DOCUMENT,
            eventSource: targetUrl,
            details: {
              ...logDetails,
              pageUrl: window.location.href
            }
          }
        }
      });
    },
    [logDetails, saveLog]
  );
}
