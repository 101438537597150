import { ColumnsType } from 'antd/es/table';
import { flow, get, map } from 'lodash/fp';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  ChangelogProjection,
  usePnlItemChangelogQuery
} from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { formatDateTime } from '~/helpers/date';
import { formatUser } from '~/helpers/user';

import { getChangelogParams, IChangeLogParamsModel } from '../utils';

import { useChangelogController } from './useChangelogController';
import { useColumns } from './useColumns';

export interface IFormattedChangeLogData extends Partial<ChangelogProjection> {
  formattedCreatedOn: string;
  userName: string;
  email: string;
}

export interface IUseChangeLogPropsResult {
  controller: IChangeLogParamsModel;
  onPageChange: (value, pageSize) => void;
  columns: ColumnsType<IFormattedChangeLogData>;
  dataSource: Array<IFormattedChangeLogData>;
  isLoading: boolean;
}

export const useChangelogProps = (): IUseChangeLogPropsResult => {
  const { id } = useParams();
  const columns = useColumns<IFormattedChangeLogData>();
  const { controller, onPageChange, updateController } =
    useChangelogController();
  const { data: rawData, loading: isLoading } = usePnlItemChangelogQuery({
    ...REQUEST_BASE_OPTIONS,
    variables: {
      search: getChangelogParams(controller),
      retailAdId: id
    },
    onCompleted: (data) =>
      updateController({
        totalPages: data?.data?.totalEntityCount
      })
  });

  const dataSource = useMemo(() => {
    const entities = rawData?.data?.entities;
    if (!entities) {
      return [] as IUseChangeLogPropsResult['dataSource'];
    }

    return map((entity: ChangelogProjection) => ({
      ...entity,
      formattedCreatedOn: flow(get('createdOn'), formatDateTime)(entity),
      userName: flow(get(['createdBy']), formatUser)(entity),
      email: get(['createdBy', 'email'])(entity)
    }))(entities as IUseChangeLogPropsResult['dataSource']);
  }, [rawData]);

  return useMemo(
    () => ({
      dataSource,
      columns,
      isLoading,
      controller,
      onPageChange
    }),
    [columns, controller, dataSource, isLoading, onPageChange]
  );
};
