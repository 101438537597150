import { useMemo } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash/fp';

import { TABS } from '../constants';
import { IUseRetailAdItemDataResult } from '../hooks/useItemData';

import { CostsTable } from './CostsTable';
import { RevenueTable } from './RevenueTable';
import { ChangeLogTable } from './ChangeLogTable';

const { TabPane } = Tabs;

export interface ITabsSectionProps extends IUseRetailAdItemDataResult {
  activeTab: TABS;
  onTabChange: (tab: TABS) => void;
}

export const TabsSection = ({
  data,
  isLoading,
  activeTab,
  onTabChange
}: ITabsSectionProps) => {
  const { t } = useTranslation();

  const tabTitles = useMemo(
    () => ({
      [TABS.COSTS]: `${t('bo.profitAndLoss.item.tabs.costs')} (${size(
        data?.costItems
      )})`,
      [TABS.REVENUE]: `${t('bo.profitAndLoss.item.tabs.revenue')} (${size(
        data?.revenueItems
      )})`,
      [TABS.CHANGELOG]: t('bo.profitAndLoss.item.tabs.changelog')
    }),
    [data, t]
  );
  return (
    <Tabs type="card" activeKey={activeTab} onChange={onTabChange}>
      <TabPane tab={tabTitles[TABS.COSTS]} key={TABS.COSTS}>
        <CostsTable data={data?.costItems} isLoading={isLoading} />
      </TabPane>
      <TabPane tab={tabTitles[TABS.REVENUE]} key={TABS.REVENUE}>
        <RevenueTable data={data?.revenueItems} isLoading={isLoading} />
      </TabPane>
      <TabPane tab={tabTitles[TABS.CHANGELOG]} key={TABS.CHANGELOG}>
        <ChangeLogTable />
      </TabPane>
    </Tabs>
  );
};
