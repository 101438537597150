import { filter } from 'lodash/fp';
import { useMemo } from 'react';

import { PnlPartnerProjection } from '~/apollo/gql-types';
import { currencyOptions } from '~/helpers/fieldOptions/currency';
import { getVatRateOptions } from '~/helpers/fieldOptions/vatRate';
import { useInvoiceTypeOptions } from '~/hooks/options/useInvoiceTypeOptions';
import { useItemTypeCategoryOptions } from '~/hooks/options/useItemTypeCategoryOptions';
import { useItemTypeOptions } from '~/hooks/options/useItemTypeOptions';
import { useItemTypesListOptions } from '~/hooks/options/useItemTypesListOptions';
import { IItemTypesListParams } from '~/hooks/options/useItemTypesListOptions/utils';
import { usePnlPartnersOptions } from '~/hooks/options/usePnlPartnersOptions';

export interface IOption {
  value: string;
  label: string;
}
export type TOptions = Array<IOption>;

export interface IFormOptions {
  invoiceTypes: TOptions;
  itemTypes: TOptions;
  names: TOptions;
  partners: TOptions;
  categories: TOptions;
  vatRates: TOptions;
  currencies: TOptions;
}

export type TUseFormOptionsParams = IItemTypesListParams;

export interface IUseFormOptions {
  options: IFormOptions;
  isLoaded: boolean;
  isLoading: boolean;
}

const STATIC_OPTIONS = {
  currencies: currencyOptions,
  vatRates: getVatRateOptions()
};

export const useFormOptions = (
  { type, category }: TUseFormOptionsParams,
  partner: PnlPartnerProjection
): IUseFormOptions => {
  const invoiceTypes = useInvoiceTypeOptions();
  const itemTypes = useItemTypeOptions();
  const { options: names, isLoading: itemTypesListLoading } =
    useItemTypesListOptions();
  const { options: partners, isLoading: partnersLoading } =
    usePnlPartnersOptions(partner);
  const { options: categories, isLoading: categoriesLoading } =
    useItemTypeCategoryOptions();

  const namesFiltered = useMemo(
    () => filter({ type, category })(names),
    [category, names, type]
  );

  const isLoading = useMemo(
    () => categoriesLoading || partnersLoading || itemTypesListLoading,
    [categoriesLoading, itemTypesListLoading, partnersLoading]
  );

  return useMemo(
    () =>
      ({
        isLoaded: !isLoading,
        isLoading,
        options: {
          names: namesFiltered,
          invoiceTypes,
          itemTypes,
          partners,
          categories,
          ...STATIC_OPTIONS
        }
      } as unknown as IUseFormOptions),
    [categories, invoiceTypes, isLoading, itemTypes, namesFiltered, partners]
  );
};
