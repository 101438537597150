import { ReactNode } from 'react';

export interface ITableCellWrapProps {
  value?: ReactNode;
  qaSelector?: string;
  className?: string;
}

export const TableCellWrap = ({
  value,
  qaSelector,
  className
}: ITableCellWrapProps) => (
  <span data-qa-selector-name={qaSelector} className={className}>
    {value}
  </span>
);
