export enum PERMISSIONS {
  QUERY_SEARCH_RETAIL_DATA_IMPORT = 'query/searchretaildataimport',
  MUTATION_IMPORT_PROFIT_AND_LOSS_ITEMS = 'mutation/importprofitandlossitems',
  MUTATION_CREATE_PNL_ITEM_TYPE = 'mutation/createpnlitemtype',
  MUTATION_DELETE_PNL_ITEM_TYPE = 'mutation/deletepnlitemtype',
  QUERY_SEARCH_PNL_ITEM_TYPES = 'query/searchpnlitemtypes',
  QUERY_RETAIL_AD_PNL_DATA = 'query/retailadpnldata',
  QUERY_SEARCH_RETAIL_AD_PNL_DATA = 'query/searchretailadpnldata',
  QUERY_SEARCH_PNL_PARTNERS = 'query/searchpnlpartners',
  MUTATION_CREATE_PNL_PARTNER = 'mutation/createpnlpartner',
  MUTATION_DELETE_PNL_PARTNER = 'mutation/deletepnlpartner',
  MUTATION_CREATE_PNL_ITEM = 'mutation/createpnlitem',
  MUTATION_UPDATE_PNL_ITEM = 'mutation/updatepnlitem',
  MUTATION_DELETE_PNL_ITEM = 'mutation/deletepnlitem'
}
