import { PathRouteProps } from 'react-router-dom';

import { ItemTypes } from './pages/ItemTypes';
import { Overview } from './pages/Overview';
import { Partners } from './pages/Partners';
import { RetailAdItem } from './pages/RetailAdItem';
import { PartnersCreate } from './pages/PartnersCreate';
import { Import } from './pages/Import';

export const ROOT = {
  ROUTE: '/:language',
  LINK: ({ language }) => `/${language}`
};

export const PROFIT_AND_LOSS = {
  ROUTE: `/profit-and-loss`,
  ROUTE_RELATIVE: `${ROOT.ROUTE}/profit-and-loss`,
  ROUTE_RELATIVE_DEEP: `${ROOT.ROUTE}/profit-and-loss/*`,
  LINK: ({ language }) => `${ROOT.LINK({ language })}/profit-and-loss`
};

export const PROFIT_AND_LOSS_ITEM = {
  ROUTE: `/profit-and-loss/:id`,
  ROUTE_RELATIVE: `${PROFIT_AND_LOSS.ROUTE_RELATIVE}/:id`,
  ROUTE_RELATIVE_DEEP: `${PROFIT_AND_LOSS.ROUTE_RELATIVE}/:id/*`,
  LINK: ({ language, retailAdId }) =>
    `${PROFIT_AND_LOSS.LINK({ language })}/${retailAdId}`
};

export const PROFIT_AND_LOSS_PARTNERS = {
  ROUTE: `/profit-and-loss/partners`,
  ROUTE_RELATIVE: `${PROFIT_AND_LOSS.ROUTE_RELATIVE}/partners`,
  ROUTE_RELATIVE_DEEP: `${PROFIT_AND_LOSS.ROUTE_RELATIVE}/partners/*`,
  LINK: ({ language }) => `${PROFIT_AND_LOSS.LINK({ language })}/partners`
};

export const PROFIT_AND_LOSS_PARTNERS_CREATE = {
  ROUTE: `/profit-and-loss/partners/create`,
  ROUTE_RELATIVE: `${PROFIT_AND_LOSS_PARTNERS.ROUTE_RELATIVE}/create`,
  ROUTE_RELATIVE_DEEP: `${PROFIT_AND_LOSS_PARTNERS.ROUTE_RELATIVE}/create/*`,
  LINK: ({ language }) =>
    `${PROFIT_AND_LOSS_PARTNERS.LINK({ language })}/create`
};

export const PROFIT_AND_LOSS_ITEM_TYPES = {
  ROUTE: `/profit-and-loss/item-types`,
  ROUTE_RELATIVE: `${PROFIT_AND_LOSS.ROUTE_RELATIVE}/item-types`,
  ROUTE_RELATIVE_DEEP: `${PROFIT_AND_LOSS.ROUTE}/item-types/*`,
  LINK: ({ language }) => `${PROFIT_AND_LOSS.LINK({ language })}/item-types`
};

export const PROFIT_AND_LOSS_IMPORT = {
  ROUTE: `import/profit-and-loss`,
  ROUTE_RELATIVE: `${ROOT.ROUTE}/import/profit-and-loss`,
  ROUTE_RELATIVE_DEEP: `${ROOT.ROUTE}/import/profit-and-loss/*`,
  LINK: ({ language }) => `${ROOT.LINK({ language })}/import/profit-and-loss`
};

// ADS
export const AD = {
  ROUTE: `${ROOT.ROUTE}/ad`,
  LINK: ({ language }) => `${ROOT.LINK({ language })}/ad`
};

export const AD_ITEM = {
  ROUTE: ':adId',
  ROUTE_RELATIVE: `${AD.ROUTE}/:adId`,
  ROUTE_RELATIVE_DEEP: `${AD.ROUTE}/:adId/*`,
  LINK: ({ language, adId }) => `${AD.LINK({ language })}/${adId}`
};

// ADMIN USERS
export const ADMIN_USERS = {
  ROUTE: `${ROOT.ROUTE}/admin/users`,
  LINK: ({ language }) => `${ROOT.LINK({ language })}/admin/users`
};

export const ADMIN_USER = {
  ROUTE: ':id',
  ROUTE_RELATIVE: `${ADMIN_USERS.ROUTE}/:id`,
  ROUTE_RELATIVE_DEEP: `${ADMIN_USERS.ROUTE}/:id/*`,
  LINK: ({ language, id }) => `${ADMIN_USERS.LINK({ language })}/${id}`
};

export const ROUTES: PathRouteProps[] = [
  {
    path: PROFIT_AND_LOSS.ROUTE_RELATIVE,
    element: <Overview />
  },
  {
    path: PROFIT_AND_LOSS_PARTNERS.ROUTE_RELATIVE,
    element: <Partners />
  },
  {
    path: PROFIT_AND_LOSS_ITEM_TYPES.ROUTE_RELATIVE,
    element: <ItemTypes />
  },
  {
    path: PROFIT_AND_LOSS_ITEM.ROUTE_RELATIVE,
    element: <RetailAdItem />
  },
  {
    path: PROFIT_AND_LOSS_PARTNERS_CREATE.ROUTE_RELATIVE,
    element: <PartnersCreate />
  },
  {
    path: PROFIT_AND_LOSS_IMPORT.ROUTE_RELATIVE,
    element: <Import />
  }
];
