import { flow, map, isObject } from 'lodash/fp';

import { encodeName } from './encodeName';

type TOption = {
  value: any;
  label: any;
};

export default flow(
  map((value) => (isObject(value) ? value : { value })),
  map(({ value, label, ...props }: TOption) => {
    label = label || value;

    return { ...props, label, value, name: encodeName(value) };
  })
);
