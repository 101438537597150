import { useMemo } from 'react';
import { uniq, map } from 'lodash/fp';

import { useGetItemTypeCategoriesQuery } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { DOMAINS } from '~/constants/permissions';
import { encodeName } from '~/helpers/fieldOptions/encodeName';
import { AntOptionProps } from '~/components/Form/SelectControlled';

import { DEFAULT_CATEGORIES } from './utils';

const ITEM_TYPES_CATEGORIES_BASE_OPTIONS = {
  ...REQUEST_BASE_OPTIONS,
  context: {
    headers: { 'access-domain': DOMAINS.ITEM_TYPES_PNL_MANAGEMENT }
  }
};

export function useItemTypeCategoryOptions() {
  const {
    data,
    loading: isLoading,
    called
  } = useGetItemTypeCategoriesQuery(ITEM_TYPES_CATEGORIES_BASE_OPTIONS);
  const isLoaded = !isLoading && called;
  const options = useMemo(() => {
    const categories = data?.pnlItemTypeCategories || [];
    const allCategories = uniq([...DEFAULT_CATEGORIES, ...categories]);
    return (
      (map((value) => ({
        value,
        label: value,
        name: encodeName(value)
      }))(allCategories) as AntOptionProps[]) ?? []
    );
  }, [data?.pnlItemTypeCategories]);

  return {
    options,
    isLoading,
    isLoaded
  };
}
