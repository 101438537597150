// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QErpCPYZDK2jJ3paBKMK {\n  display: flex;\n  grid-gap: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Import/Actions/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;AACF","sourcesContent":[".actions {\n  display: flex;\n  grid-gap: 10px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "QErpCPYZDK2jJ3paBKMK"
};
export default ___CSS_LOADER_EXPORT___;
