// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SKScYqzvungF9EG7qAka .antd-4-21-7-form-item-label {\n  padding-bottom: 0 !important;\n}\n.SKScYqzvungF9EG7qAka .antd-4-21-7-form-item {\n  display: inline-block;\n  width: 200px;\n  margin-right: 1.5rem;\n  margin-bottom: 0;\n}\n.SKScYqzvungF9EG7qAka .VsbtQ1ETZ8lVH_ef5Je9 {\n  display: inline-block;\n  vertical-align: bottom;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ItemTypes/SearchForm/style.less"],"names":[],"mappings":"AAKM;EACE,4BAAA;AAJR;AAOM;EACE,qBAAA;EACA,YAAA;EACA,oBAAA;EACA,gBAAA;AALR;AANA;EAiBI,qBAAA;EACA,sBAAA;AARJ","sourcesContent":["@import '../../../styles/constants.less';\n\n.form {\n  :global {\n    .@{antd-version} {\n      &-form-item-label {\n        padding-bottom: 0 !important;\n      }\n\n      &-form-item {\n        display: inline-block;\n        width: 200px;\n        margin-right: 1.5rem;\n        margin-bottom: 0;\n      }\n    }\n  }\n\n  .submit {\n    display: inline-block;\n    vertical-align: bottom;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "SKScYqzvungF9EG7qAka",
	"submit": "VsbtQ1ETZ8lVH_ef5Je9"
};
export default ___CSS_LOADER_EXPORT___;
