import {
  createFilter,
  createFilterPayload,
  FilterType,
  SortType
} from '@retail/gql-utils';

import { SearchProjection2Input } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { DOMAINS } from '~/constants/permissions';

import { PnlSearchModel } from './SearchForm';

export const PNL_BASE_OPTIONS = {
  ...REQUEST_BASE_OPTIONS,
  context: {
    headers: { 'access-domain': DOMAINS.PNL_MANAGEMENT }
  }
};

export interface PnlParamsModel
  extends Partial<PnlSearchModel>,
    Pick<SearchProjection2Input, 'page' | 'pageSize'> {
  totalPages?: number;
}

const sorts = [
  {
    property: 'stockNumber',
    direction: SortType.ASC
  }
];

export function getPnlParams(
  { page: rawPage, pageSize, stockNumber }: PnlParamsModel,
  userAccessibleCountryCodes
) {
  const page = Number(rawPage) - 1 || 0;
  const filter = [
    createFilter('retailCountry', FilterType.IN, userAccessibleCountryCodes)
  ];

  if (stockNumber) {
    filter.push(createFilter('stockNumber', FilterType.EQUAL, stockNumber));
  }

  const filterQuery =
    filter.length > 1 ? createFilter(null, FilterType.AND, filter) : filter[0];

  return createFilterPayload({
    filter: filterQuery,
    sorts,
    page,
    pageSize
  });
}
