import { useCallback } from 'react';
import { flow, first, get } from 'lodash/fp';

import { useLoadPresignedUrlsLazyQuery } from '~/apollo/gql-types';
import { PNL_IMPORT_BASE_OPTIONS } from '~/pages/Import/utils';

export const useGetDownloadUrl = () => {
  const [loadPreSignedUrls] = useLoadPresignedUrlsLazyQuery({
    ...PNL_IMPORT_BASE_OPTIONS
  });

  return useCallback(
    async (fileName) => {
      const entities = [
        {
          fileName,
          httpMethod: 'GET'
        }
      ];

      const {
        data: { data }
      } = await loadPreSignedUrls({
        variables: {
          entities
        }
      });

      return flow(first, get(['presignedUrl']))(data);
    },
    [loadPreSignedUrls]
  );
};
