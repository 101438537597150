import { FunctionComponent, PropsWithChildren } from 'react';

import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import {
  IUseCheckPermissionsProps,
  useCheckPermissions
} from '~/hooks/useCheckPermissions';

export type IPermissionCheckerProps =
  PropsWithChildren<IUseCheckPermissionsProps>;

export const PermissionChecker = ({
  children,
  ...permissionProps
}: IPermissionCheckerProps) => {
  const { isAllowed } = useCheckPermissions(permissionProps);

  if (isAllowed) {
    return <>{children}</>;
  }

  return null;
};

export function withPermissions<Props>(allow: PERMISSIONS, domain: DOMAINS) {
  return (Component: FunctionComponent<Props>) => (props?: Props) =>
    (
      <PermissionChecker allow={allow} domain={domain}>
        <Component {...props} />
      </PermissionChecker>
    );
}
