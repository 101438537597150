import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PNL_ITEM_TYPES } from '~/constants/pnl';

export const OPTIONS = [
  {
    label: `bo.profitAndLoss.itemType.${PNL_ITEM_TYPES.COST}`,
    value: PNL_ITEM_TYPES.COST
  },
  {
    label: `bo.profitAndLoss.itemType.${PNL_ITEM_TYPES.REVENUE}`,
    value: PNL_ITEM_TYPES.REVENUE
  }
];

export function useItemTypeOptions() {
  const { t } = useTranslation();

  return useMemo(() => OPTIONS.map((x) => ({ ...x, label: t(x.label) })), [t]);
}
