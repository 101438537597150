import { useMemo } from 'react';

import {
  PnlItemProjection,
  RetailAdPnlDataProjection
} from '~/apollo/gql-types';
import {
  getPnlItemCategory,
  getPnlItemEntityChargedName,
  getPnlItemGrossAmountMinorUnits,
  getPnlItemId,
  getPnlItemInvoiceDate,
  getPnlItemInvoiceNumber,
  getPnlItemNetAmountMinorUnits,
  getPnlItemPartnerId,
  getPnlItemPnlInvoiceType,
  getPnlItemServiceEndDate,
  getPnlItemServiceId,
  getPnlItemType,
  getPnlItemTypeId,
  getPnlItemVatAmountMinorUnits,
  getPnlItemVatRate
} from '~/utils/pnlItem';
import {
  getAdCountry,
  getAdCurrency,
  getAdId,
  getStockNumber,
  getVin
} from '~/utils/retailAd';

/** @description in case we have `undefined` in field `react-hook-form` won't reset value */
export const useInitialValues = (
  data: RetailAdPnlDataProjection,
  item: PnlItemProjection
) =>
  useMemo(
    () =>
      data
        ? {
            id: getPnlItemId(item) ?? null,
            retailAdId: getAdId(data) ?? null,
            retailCountry: getAdCountry(data) ?? null,
            stockNumber: getStockNumber(data) ?? null,
            vin: getVin(data) ?? null,
            currencyCode: getAdCurrency(data) ?? null,
            type: getPnlItemType(item) ?? null,
            category: getPnlItemCategory(item) ?? null,
            pnlItemTypeId: getPnlItemTypeId(item) ?? null,
            entityChargedName: getPnlItemEntityChargedName(item) ?? null,
            pnlInvoiceType: getPnlItemPnlInvoiceType(item) ?? null,
            netAmountMinorUnits: getPnlItemNetAmountMinorUnits(item) ?? null,
            grossAmountMinorUnits:
              getPnlItemGrossAmountMinorUnits(item) ?? null,
            vatAmountMinorUnits: getPnlItemVatAmountMinorUnits(item) ?? null,
            vatRate: getPnlItemVatRate(item) ?? null,
            invoiceNumber: getPnlItemInvoiceNumber(item) ?? null,
            invoiceDate: getPnlItemInvoiceDate(item) ?? null,
            serviceId: getPnlItemServiceId(item) ?? null,
            serviceEndDate: getPnlItemServiceEndDate(item) ?? null,
            partnerId: getPnlItemPartnerId(item) ?? null
          }
        : {},
    [data, item]
  );
