// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrbX_FT7gp8FVfC1udlH {\n  text-align: left;\n  font-weight: 100;\n}\n", "",{"version":3,"sources":["webpack://./src/components/InfoRow/styles.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EAEA,gBAAA;AAAF","sourcesContent":[".label {\n  text-align: left;\n\n  font-weight: 100;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "TrbX_FT7gp8FVfC1udlH"
};
export default ___CSS_LOADER_EXPORT___;
