import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Form } from 'antd';
import { Dispatch } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectControlled } from '~/components/Form/SelectControlled';
import { InputControlled } from '~/components/Form/InputControlled';
import { useCountriesOptions } from '~/hooks/options/useCountriesOptions';

import { useSortByOptions } from '../hooks/useSortByOptions';

import cn from './style.less';

export interface PartnersSearchModel {
  country?: string[];
  partnerId?: string;
  partnerVatId?: string;
  partnerName?: string;
  sortBy?: string;
}

interface Props extends PartnersSearchModel {
  onSubmit: Dispatch<PartnersSearchModel>;
  isLoading: boolean;
}

export function SearchForm({
  country,
  partnerId,
  partnerVatId,
  partnerName,
  sortBy,
  isLoading,
  onSubmit
}: Props) {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<PartnersSearchModel>({
    defaultValues: {
      country,
      partnerId,
      partnerVatId,
      partnerName,
      sortBy
    }
  });

  const countriesOptions = useCountriesOptions();
  const sortByOptions = useSortByOptions();

  return (
    <Card
      title={t('bo.profitAndLoss.partners.searchForm.title')}
      loading={false}
    >
      <Form
        disabled={isLoading}
        onFinish={handleSubmit(onSubmit)}
        data-qa-selector-name="searchForm"
        className={cn.form}
      >
        <SelectControlled
          allowClear
          options={countriesOptions}
          mode="multiple"
          className={cn.countryField}
          qaSelector="searchForm-country"
          label={t('bo.profitAndLoss.partners.searchForm.country.label')}
          placeholder={t(
            'bo.profitAndLoss.partners.searchForm.country.placeholder'
          )}
          controllerProps={{
            control,
            name: 'country'
          }}
        />
        <InputControlled
          type="string"
          allowClear
          labelCol={{ span: 24 }}
          disabled={isLoading}
          qaSelector="searchForm-partnerId"
          label={t('bo.profitAndLoss.partners.searchForm.partnerID.label')}
          placeholder={t(
            'bo.profitAndLoss.partners.searchForm.partnerID.placeholder'
          )}
          controllerProps={{
            name: 'partnerId',
            control
          }}
        />
        <InputControlled
          type="string"
          allowClear
          labelCol={{ span: 24 }}
          qaSelector="searchForm-partnerName"
          disabled={isLoading}
          label={t('bo.profitAndLoss.partners.searchForm.partnerName.label')}
          placeholder={t(
            'bo.profitAndLoss.partners.searchForm.partnerName.placeholder'
          )}
          controllerProps={{
            name: 'partnerName',
            control
          }}
        />
        <InputControlled
          type="string"
          allowClear
          labelCol={{ span: 24 }}
          qaSelector="searchForm-partnerVatId"
          disabled={isLoading}
          label={t('bo.profitAndLoss.partners.searchForm.vatID.label')}
          placeholder={t(
            'bo.profitAndLoss.partners.searchForm.vatID.placeholder'
          )}
          controllerProps={{
            name: 'partnerVatId',
            control
          }}
        />
        <SelectControlled
          allowClear
          options={sortByOptions}
          qaSelector="searchForm-sortBy"
          label={t('bo.profitAndLoss.partners.searchForm.sortBy.label')}
          placeholder={t(
            'bo.profitAndLoss.partners.searchForm.sortBy.placeholder'
          )}
          controllerProps={{
            control,
            name: 'sortBy'
          }}
        />
        <Button
          type="primary"
          htmlType="submit"
          data-qa-selector-name="searchButton"
          className={cn.submit}
          disabled={isLoading}
          icon={<SearchOutlined />}
        />
      </Form>
    </Card>
  );
}
