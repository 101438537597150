import { flow, get } from 'lodash/fp';

export const getPnlItemId = get(['id']);
export const getPnlItemTypeEntity = get(['type']);
export const getPnlItemType = flow(getPnlItemTypeEntity, get(['type']));
export const getPnlItemCategory = flow(getPnlItemTypeEntity, get(['category']));
export const getPnlItemTypeId = flow(getPnlItemTypeEntity, get(['id']));

export const getPnlItemEntityChargedName = get(['entityChargedName']);
export const getPnlItemPnlInvoiceType = get(['pnlInvoiceType']);

export const getPnlItemNetAmountMinorUnits = get(['netAmountMinorUnits']);
export const getPnlItemGrossAmountMinorUnits = get(['grossAmountMinorUnits']);
export const getPnlItemVatAmountMinorUnits = get(['vatAmountMinorUnits']);

export const getPnlItemVatRate = get(['vatRate']);
export const getPnlItemInvoiceNumber = get(['invoiceNumber']);
export const getPnlItemInvoiceDate = get(['invoiceDate']);
export const getPnlItemServiceId = get(['serviceId']);
export const getPnlItemServiceEndDate = get(['serviceEndDate']);

export const getPnlItemPartner = get(['partner']);
export const getPnlItemPartnerId = flow(getPnlItemPartner, get(['id']));
