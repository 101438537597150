import { InboxOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { filter, flow, join } from 'lodash/fp';
import cns from 'classnames';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ErrorCode,
  FileRejection,
  useDropzone,
  DropzoneProps,
  DropzoneOptions
} from 'react-dropzone';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';

import { FormItem } from '~/components/Form/FormItem';
import { FIELDS_LABEL_COL_SPAN } from '~/constants/form';

import { FileList } from './FileList';
import cn from './styles.less';

type Props<T extends FieldValues & DropzoneProps> = {
  label: string;
  maxSizeMB: number;
  required?: boolean;
  accept: DropzoneOptions['accept'];
  qaSelector: string;
  placeholder?: string;
  hint?: string;
  disabled?: boolean;
  controllerProps: UseControllerProps<T>;
};

export const DropzoneControlled = memo<Props<FieldValues>>(
  ({
    disabled,
    hint,
    label,
    required,
    placeholder,
    maxSizeMB,
    accept,
    qaSelector,
    controllerProps
  }) => {
    const { field } = useController({
      ...controllerProps
    });
    const { t } = useTranslation();
    const defaultPlaceholder = t(t('bo.form.dropzonePlaceholder'));
    const defaultHint = useMemo(() => {
      const acceptText =
        accept && `${t('bo.form.dropzoneAcceptsPlaceholder')}: ${accept}`;
      const maxSizeText =
        maxSizeMB &&
        `${t('bo.form.dropzoneFileSizePlaceholder')}: ${maxSizeMB}MB`;

      return flow(
        filter((value) => value),
        join('; ')
      )([acceptText, maxSizeText]);
    }, [accept, maxSizeMB, t]);

    const onDrop = useCallback(
      async (accepted: File[], rejected: FileRejection[]) => {
        if (rejected?.length) {
          notification.warning({
            message: 'Error', // TODO Add translations
            description: rejected
              .map(({ file, errors }) =>
                errors
                  ?.map(({ code, message }) =>
                    code === ErrorCode.FileInvalidType
                      ? `Invalid file type: ${file?.name} ${file?.type}`
                      : message
                  )
                  .join(', ')
              )
              .join(', ')
          });
        } else {
          field.onChange(accepted.concat(field.value || []));
        }
      },
      [field]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      disabled,
      multiple: true,
      accept,
      maxSize: 1024 * 1024 * maxSizeMB
    });

    const onRemove = useCallback(
      (item, index) => {
        field.onChange(field.value.filter((_, i) => i !== index));
      },
      [field]
    );

    return (
      <FormItem
        label={label}
        labelCol={{ span: FIELDS_LABEL_COL_SPAN }}
        required={required}
        disabled={disabled}
        controllerProps={controllerProps as unknown as UseControllerProps}
      >
        <div>
          <div
            {...getRootProps()}
            data-qa-selector-name={qaSelector}
            className={cns(cn.dropzone, { active: isDragActive })}
          >
            <input {...getInputProps()} id="fileUpload" />
            <InboxOutlined style={{ fontSize: 42, color: '#6EB5F2' }} />
            <p className={cn.placeholder}>
              {placeholder || defaultPlaceholder}
            </p>
            <span className={cn.hint}>{hint || defaultHint}</span>
          </div>
        </div>
        {Boolean(field?.value?.length) && (
          <FileList list={field.value} handleRemove={onRemove} />
        )}
      </FormItem>
    );
  }
);
