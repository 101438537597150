import { useCallback } from 'react';

import {
  PnlItemUpdateProjectionInput,
  useUpdatePnlItemMutation
} from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

export const useUpdateItem = () => {
  const [updateItem] = useUpdatePnlItemMutation({
    ...REQUEST_BASE_OPTIONS,
    refetchQueries: ['RetailAdPnlData', 'PnlItemChangelog']
  });

  return useCallback(
    async (id: string, item: PnlItemUpdateProjectionInput) => {
      await updateItem({ variables: { id, item } });
    },
    [updateItem]
  );
};
