import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PnlItemTypeProjection } from '~/apollo/gql-types';
import { TableCellWrap } from '~/components/TableCellWrap';
import { formatDateTime } from '~/helpers/date';

import { Actions } from '../Actions';

export function useItemTypesColumns() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          title: t('bo.profitAndLoss.itemTypes.table.category'),
          dataIndex: 'category',
          render: (category) => (
            <TableCellWrap value={category} qaSelector="category" />
          )
        },
        {
          title: t('bo.profitAndLoss.itemTypes.table.itemName'),
          dataIndex: 'name',
          render: (name) => <TableCellWrap value={name} qaSelector="name" />
        },
        {
          title: t('bo.profitAndLoss.itemTypes.table.itemType'),
          dataIndex: 'type',
          render: (type) => <TableCellWrap value={type} qaSelector="type" />
        },
        {
          title: t('bo.profitAndLoss.itemTypes.table.description'),
          dataIndex: 'description',
          render: (description) => (
            <TableCellWrap value={description} qaSelector="description" />
          )
        },
        {
          title: t('bo.profitAndLoss.itemTypes.table.createdOn'),
          dataIndex: 'createdOn',
          render: (createdOn) => (
            <TableCellWrap
              value={formatDateTime(createdOn)}
              qaSelector="createdOn"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.itemTypes.table.actions'),
          align: 'center',
          dataIndex: 'actions',
          render: (_, record) => <Actions record={record} />
        }
      ] as ColumnsType<PnlItemTypeProjection>,
    [t]
  );
}
