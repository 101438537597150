import { Typography } from 'antd';
import { PropsWithChildren } from 'react';

const { Text } = Typography;

interface Props {
  required: boolean;
}

export function LabelText({ children, required }: PropsWithChildren<Props>) {
  return (
    <Text data-qa-selector="label">
      {children}
      {required ? (
        <>
          &nbsp;
          <Text type="danger">*</Text>
        </>
      ) : null}
    </Text>
  );
}
