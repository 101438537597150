import { useCallback } from 'react';
import { map, pick } from 'lodash/fp';

export const useUploadToS3 = () =>
  useCallback(async (files, credentials) => {
    const fileRequests = await credentials.map(
      async ({ presignedUrl, httpMethod }, i) => {
        const file = files[i];

        return await fetch(presignedUrl, {
          body: file,
          method: httpMethod
        });
      }
    );

    await Promise.all(fileRequests);

    return map(pick(['fileName']), credentials);
  }, []);
