import { COUNTRIES_VALUES } from '@retail/i18n/constants';
import localizedCountries from 'localized-countries/data/en_GB.json';
import {
  flow,
  get,
  identity,
  isFunction,
  isString,
  map,
  sortBy
} from 'lodash/fp';
import { useMemo } from 'react';

import { AntOptionProps } from '~/components/Form/SelectControlled';

const GB = 'GB';
const SK = 'SK';
const LU = 'LU';
const list = [...COUNTRIES_VALUES, SK, GB, LU];

export const toLocalizedCountry = (countryCode) =>
  get(countryCode, localizedCountries);

const prepareFormattedOptions = (...args) => {
  let [formatLabel, prop] = args;

  if (isString(formatLabel)) {
    prop = formatLabel;
  }

  if (!isFunction(formatLabel)) {
    formatLabel = identity;
  }

  return map((item) => {
    let value = item;

    if (prop) {
      value = item[prop];
    }

    return {
      value,
      label: formatLabel(value)
    };
  });
};

const prepareCountryOptions = flow(
  prepareFormattedOptions(toLocalizedCountry),
  sortBy('label')
);

export function usePartnerCountryOptions() {
  return useMemo(
    () => prepareCountryOptions(list) as unknown as AntOptionProps[],
    []
  );
}
