import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import {
  ROOT,
  PROFIT_AND_LOSS,
  PROFIT_AND_LOSS_PARTNERS,
  PROFIT_AND_LOSS_ITEM_TYPES
} from 'routes';

const { TabPane } = Tabs;

export const NavigationTabs = () => {
  const { t } = useTranslation();
  const { language } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activeTabKey = useMemo(() => {
    if (pathname.includes(PROFIT_AND_LOSS_PARTNERS.ROUTE)) {
      return PROFIT_AND_LOSS_PARTNERS.ROUTE;
    }
    if (pathname.includes(PROFIT_AND_LOSS_ITEM_TYPES.ROUTE)) {
      return PROFIT_AND_LOSS_ITEM_TYPES.ROUTE;
    }

    return PROFIT_AND_LOSS.ROUTE;
  }, [pathname]);

  const handleTabClick = useCallback(
    (key) => {
      navigate(`${ROOT.LINK({ language })}${key}`);
    },
    [language, navigate]
  );

  return (
    <Tabs type="card" activeKey={activeTabKey} onTabClick={handleTabClick}>
      <TabPane
        tab={t('bo.profitAndLoss.navTabs.overview')}
        key={PROFIT_AND_LOSS.ROUTE}
      />
      <TabPane
        tab={t('bo.profitAndLoss.navTabs.itemTypes')}
        key={PROFIT_AND_LOSS_ITEM_TYPES.ROUTE}
      />
      <TabPane
        tab={t('bo.profitAndLoss.navTabs.partners')}
        key={PROFIT_AND_LOSS_PARTNERS.ROUTE}
      />
    </Tabs>
  );
};
