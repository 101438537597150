import { useCallback, useEffect, useMemo, useState } from 'react';

export type TUseModalState<Data = any> = Partial<IModal<Data>>;
export type TUseModalParams = TUseModalState;

export interface IModal<Data = any> {
  isOpen: boolean;
  data?: Data;
}

export interface IUseModal<Data = any> {
  modalState: TUseModalState<Data>;
  openModal: (data?: IModal<Data>['data']) => void;
  closeModal: (data?: IModal<Data>['data']) => void;
}

const DEFAULT_STATE = {
  isOpen: false,
  data: null
};

export function useModal(
  defaultState: TUseModalParams = DEFAULT_STATE
): IUseModal {
  const [modalState, setModalState] = useState<TUseModalState>(defaultState);

  const openModal = useCallback(
    (data) => {
      setModalState({
        isOpen: true,
        data
      });
    },
    [setModalState]
  );

  const closeModal = useCallback(
    (data) => {
      setModalState({
        isOpen: false,
        data
      });
    },
    [setModalState]
  );

  useEffect(() => () => setModalState(null), []);

  return useMemo(
    () => ({
      modalState,
      openModal,
      closeModal
    }),
    [modalState, openModal, closeModal]
  );
}
