import { useCallback } from 'react';
import { Tooltip, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { makeVar, useReactiveVar } from '@apollo/client';

import { useDocumentViewLogging } from '~/hooks//logging/useDocumentViewLogging';

import { useGetDownloadUrl } from '../hooks/useGetDownloadUrl';

import cn from './styles.less';

const isLoadingVar = makeVar(false);

interface IActionProps {
  resourceLocation: string;
  errorsResourceLocation: string;
}

export const Actions = ({
  resourceLocation,
  errorsResourceLocation
}: IActionProps) => {
  const { t } = useTranslation();
  const getDownloadUrl = useGetDownloadUrl();

  const isLoading = useReactiveVar(isLoadingVar);
  const handleDocumentLogging = useDocumentViewLogging();

  const handleDownload = useCallback(
    async (fileName) => {
      isLoadingVar(true);

      const url = await getDownloadUrl(fileName);

      handleDocumentLogging(url);

      isLoadingVar(false);
      if (url) {
        global.open(url, '_blank');
      }
    },
    [getDownloadUrl, handleDocumentLogging]
  );

  if (!resourceLocation && !errorsResourceLocation) {
    return null;
  }

  return (
    <div className={cn.actions}>
      {resourceLocation && (
        <Tooltip title={t('bo.profitAndLoss.imports.buttons.downloadOriginal')}>
          <Button
            size="small"
            type="primary"
            disabled={isLoading}
            data-qa-selector-name="downloadOriginal"
            icon={<CloudDownloadOutlined />}
            onClick={() => handleDownload(resourceLocation)}
          />
        </Tooltip>
      )}

      {errorsResourceLocation && (
        <Tooltip title={t('bo.profitAndLoss.imports.buttons.downloadError')}>
          <Button
            size="small"
            type="primary"
            danger
            disabled={isLoading}
            data-qa-selector-name="downloadErrors"
            icon={<CloudDownloadOutlined />}
            onClick={() => handleDownload(errorsResourceLocation)}
          />
        </Tooltip>
      )}
    </div>
  );
};
