import { Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { MdFinanceIcon } from '~/components/Icons/MdFinanceIcon';
import { Layout, LayoutBody, LayoutHeader } from '~/components/Layout';
import { withPermissions } from '~/components/PermissionChecker';
import { PaginationRow } from '~/components/PaginationRow';
import { DOMAINS } from '~/constants/permissions/domains';
import { PERMISSIONS } from '~/constants/permissions/permissions';

import { NavigationTabs } from '../NavigationTabs';

import { useOverviewProps } from './hooks/useOverviewProps';
import { SearchForm } from './SearchForm';
import cn from './styles.less';

export const Overview = withPermissions(
  PERMISSIONS.QUERY_SEARCH_RETAIL_AD_PNL_DATA,
  DOMAINS.PNL_MANAGEMENT
)(() => {
  const { t } = useTranslation();
  const { controller, columns, dataSource, isLoading, onPageChange, onSubmit } =
    useOverviewProps();

  return (
    <Layout>
      <LayoutHeader
        title={t('bo.profitAndLoss.overview.title')}
        icon={<MdFinanceIcon />}
      />

      <NavigationTabs />

      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <SearchForm
            stockNumber={controller.stockNumber}
            isLoading={isLoading}
            onSubmit={onSubmit}
          />
          <PaginationRow
            showTotalResults
            isLoading={isLoading}
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
          />
          <Table
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            loading={isLoading}
            pagination={false}
          />
          <PaginationRow
            isLoading={isLoading}
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
          />
        </Space>
      </LayoutBody>
    </Layout>
  );
});
