import { useMemo } from 'react';

import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { useUser } from '~/hooks/useUser';

import { getGQLPermissions, hasPermissions } from './utils';

export interface IUseCheckPermissionsProps {
  allow: PERMISSIONS | PERMISSIONS[];
  domain: DOMAINS | DOMAINS[];
}

export interface IUseCheckPermissions {
  isAllowed: boolean;
  isDenied: boolean;
  isLoading: boolean;
}

export function useCheckPermissions({
  allow,
  domain
}: IUseCheckPermissionsProps): IUseCheckPermissions {
  const permissionsData = useUser();

  return useMemo(() => {
    const { permissions, isLoaded } = permissionsData;
    const allowPerms = getGQLPermissions(allow, domain);
    const isValid = hasPermissions(permissions, allowPerms);

    return {
      isAllowed: isLoaded && isValid,
      isDenied: isLoaded && !isValid,
      isLoading: !isLoaded
    };
  }, [allow, domain, permissionsData]);
}
