import { filter, get } from 'lodash/fp';

import { countriesOptions } from '~/helpers/fieldOptions/country';
import { getUserAccess, hasAccess } from '~/hooks/useCheckPermissions/utils';

export const getUserAccessibleCountryCodeOptions = (accessParameters) => {
  const userAccess = getUserAccess('country')(accessParameters);
  return filter(({ value }) => hasAccess(value, userAccess))(countriesOptions);
};

export const getUserAccessibleCountryCodes = (accessParameters) => {
  const options = getUserAccessibleCountryCodeOptions(accessParameters);

  return options.map(get(['value']));
};
