import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import { Control } from 'react-hook-form';

import { DropzoneControlled } from '~/components/Form/DropzoneControlled';

import {
  useUploadFormProps,
  IUseUploadFormProps
} from './hooks/useUploadFormProps';

const maxFileSizeMB = 1;

export function UploadModalForm(props: IUseUploadFormProps) {
  const { t } = useTranslation();
  const { control, handleSubmit, isLoading, modalProps } =
    useUploadFormProps(props);

  return (
    <Modal {...modalProps}>
      <Form onFinish={handleSubmit}>
        <DropzoneControlled
          required
          disabled={isLoading}
          maxSizeMB={maxFileSizeMB}
          qaSelector="csv-dropzone"
          accept={'.csv, text/csv'}
          label={t('bo.profitAndLoss.import.modal.uploadCSV.file')}
          controllerProps={{
            name: 'files',
            control: control as Control
          }}
        />
      </Form>
    </Modal>
  );
}
