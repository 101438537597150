import { Form, FormItemProps } from 'antd';
import { PropsWithChildren } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { LabelText } from './LabelText';
import { useRequiredRule } from './useRequiredRule';

interface Props extends Omit<FormItemProps, 'validateStatus' | 'help'> {
  controllerProps: UseControllerProps;
  disabled: boolean;
  required: boolean;
  errorMessage?: string;
}

export function FormItem({
  controllerProps: dirtyControllerProps,
  required,
  disabled,
  label,
  errorMessage,
  ...rest
}: PropsWithChildren<Props>) {
  /** disabled field can not be required */
  const isRequired = required ? !disabled : false;
  const controllerProps = useRequiredRule(dirtyControllerProps, isRequired);
  const {
    fieldState: { error }
  } = useController(controllerProps);
  const isEnabledError = errorMessage || (error && !disabled);

  return (
    <Form.Item
      {...rest}
      validateStatus={isEnabledError ? 'error' : null}
      help={isEnabledError ? errorMessage ?? error?.message : null}
      colon={rest.colon ?? false}
      label={
        label ? <LabelText required={isRequired}>{label}</LabelText> : null
      }
    />
  );
}
