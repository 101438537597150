import { useState } from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { withPermissions } from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { Layout, LayoutBody, LayoutHeader } from '~/components/Layout';
import { MdFinanceIcon } from '~/components/Icons/MdFinanceIcon';
import { ModalProvider } from '~/providers/ModalProvider';
import { getStockNumber } from '~/utils/retailAd';

import { useItemData } from './hooks/useItemData';
import { TABS } from './constants';
import { Summary } from './Summary';
import { TabsSection } from './TabsSection';
import { FormModal } from './FormModal';
import { Actions } from './Actions';
import cn from './styles.less';

export const RetailAdItem = withPermissions(
  PERMISSIONS.QUERY_RETAIL_AD_PNL_DATA,
  DOMAINS.PNL_MANAGEMENT
)(() => {
  const { t } = useTranslation();
  const { data, isLoading } = useItemData();
  const [activeTab, setActiveTab] = useState(TABS.COSTS);

  return (
    <ModalProvider modalComponent={<FormModal retailAdData={data} />}>
      <Layout className={cn.wrapper}>
        <LayoutHeader
          title={`${t('bo.profitAndLoss.item.title')} ${
            getStockNumber(data) ?? ''
          }`}
          icon={<MdFinanceIcon />}
        />

        <Actions />

        <LayoutBody>
          <Space direction="vertical" size="large" className={cn.fullWidth}>
            <Summary data={data} isLoading={isLoading} />

            <TabsSection
              data={data}
              activeTab={activeTab}
              onTabChange={setActiveTab}
              isLoading={isLoading}
            />
          </Space>
        </LayoutBody>
      </Layout>
    </ModalProvider>
  );
});
