import { Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCellWrap } from '~/components/TableCellWrap';
import { renderValue } from '~/helpers/common';

export const useColumns = <T extends object>() => {
  const { t } = useTranslation();

  function renderCreatedOnAndBy(createdOn, { userName, email }) {
    return (
      <div>
        <small data-qa-selector-name="createdOn">
          {renderValue(createdOn)}
        </small>
        <div>
          <Tag color="#000" data-qa-selector-name="userName">
            {userName}
          </Tag>
          <br />
          <Tag color="#000" data-qa-selector-name="email">
            {email}
          </Tag>
        </div>
      </div>
    );
  }

  function renderAction(action) {
    return (
      <Tag color="#87d068" data-qa-selector-name="action">
        {action}
      </Tag>
    );
  }

  function renderValueChange(newValue, { oldValue, action }) {
    return (
      <>
        <span data-qa-selector-name="oldValue">{renderValue(oldValue)}</span>{' '}
        &rarr;{' '}
        <span data-qa-selector-name="newValue">
          {renderValue(
            action === 'insert'
              ? t('bo.profitAndLoss.item.changelog.table.actions.created')
              : newValue
          )}
        </span>
      </>
    );
  }

  return useMemo(
    () =>
      [
        {
          title: t('bo.profitAndLoss.item.changelog.table.dateUser'),
          dataIndex: 'formattedCreatedOn',
          render: renderCreatedOnAndBy
        },
        {
          title: t('bo.profitAndLoss.item.changelog.table.action'),
          dataIndex: 'action',
          render: renderAction
        },
        {
          title: t('bo.profitAndLoss.item.changelog.table.field'),
          dataIndex: 'field',
          align: 'center',
          render: (field) => (
            <TableCellWrap value={renderValue(field)} qaSelector="field" />
          )
        },
        {
          title: t('bo.profitAndLoss.item.changelog.table.value'),
          dataIndex: 'newValue',
          align: 'center',
          render: renderValueChange
        }
      ] as ColumnsType<T>,
    [t]
  );
};
