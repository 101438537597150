// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hq6s04w5Gh8zVl72VJUG {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  grid-gap: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/RetailAdItem/TabsSection/CostRevenueTable/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACF","sourcesContent":[".actionsHolder {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  grid-gap: 1.5rem;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsHolder": "Hq6s04w5Gh8zVl72VJUG"
};
export default ___CSS_LOADER_EXPORT___;
