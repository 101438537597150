import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Typography, Space } from 'antd';
import { AD_ITEM } from 'routes';

import { RetailAdPnlDataProjection } from '~/apollo/gql-types';
import { InfoRow } from '~/components/InfoRow';
import { CountryFlag } from '~/components/CountryFlag';

import { useItemFormattedData } from '../hooks/useItemFormattedData';

import cn from './styles.less';

const { Link } = Typography;

export interface ISummaryProps {
  data?: RetailAdPnlDataProjection;
  isLoading: boolean;
}

export const Summary = ({ data: rawData, isLoading }: ISummaryProps) => {
  const { t } = useTranslation();
  const { language } = useParams();
  const data = useItemFormattedData(rawData);

  const renderTotal = useCallback(
    (price, count = 0) => `${price} (${count})`,
    []
  );

  const renderCountry = useCallback(
    (country) =>
      country ? (
        <CountryFlag placement="top" style={{ width: 25 }} country={country} />
      ) : null,
    []
  );

  return (
    <Card
      loading={isLoading}
      size="small"
      className={cn.infoCard}
      headStyle={{ background: '#f5f5f5', fontWeight: 'normal' }}
      title={t('bo.orderClaims.orderSummary.title.orderSummary')}
    >
      <Space direction="vertical" size="small" className={cn.cardWidth}>
        <InfoRow
          label={t('bo.profitAndLoss.item.retailCountry')}
          value={renderCountry(data?.retailCountry)}
        />
        <InfoRow
          label={t('bo.profitAndLoss.item.vehicle')}
          value={data?.title}
        />
        {data?.adId && (
          <InfoRow
            label="Stock No."
            value={
              <Link href={AD_ITEM.LINK({ language, adId: data.adId })}>
                {data?.stockNumber}
              </Link>
            }
          />
        )}
        <InfoRow label={t('bo.profitAndLoss.item.vin')} value={data?.vin} />
        <InfoRow
          label={t('bo.profitAndLoss.item.firstPublishedDate')}
          value={data?.firstPublishedOn}
        />
        <InfoRow
          label={t('bo.profitAndLoss.item.signedContract')}
          value={data?.contractSignedOn}
        />
        <InfoRow
          label={t('bo.profitAndLoss.item.carHandoverDate')}
          value={data?.vehicleDeliveredOn}
        />
        <InfoRow
          label={t('bo.profitAndLoss.item.costNetTotal')}
          value={renderTotal(data?.costNetTotal, data?.costItemCount)}
        />
        <InfoRow
          label={t('bo.profitAndLoss.item.costGrossTotal')}
          value={renderTotal(data?.costGrossTotal, data?.costItemCount)}
        />
        <InfoRow
          label={t('bo.profitAndLoss.item.revenueNetTotal')}
          value={renderTotal(data?.revenueNetTotal, data?.revenueItemCount)}
        />
        <InfoRow
          label={t('bo.profitAndLoss.item.revenueGrossTotal')}
          value={renderTotal(data?.revenueGrossTotal, data?.revenueItemCount)}
        />
      </Space>
    </Card>
  );
};
