import { useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { PROFIT_AND_LOSS_ITEM } from 'routes';

import { PermissionChecker } from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { RetailAdPnlDataProjection } from '~/apollo/gql-types';

interface Props {
  retailAdId: RetailAdPnlDataProjection['retailAd']['id'];
}

export const Actions = ({ retailAdId }: Props) => {
  const { language } = useParams();

  return (
    <PermissionChecker
      domain={DOMAINS.PNL_MANAGEMENT}
      allow={PERMISSIONS.QUERY_RETAIL_AD_PNL_DATA}
    >
      <Button
        size="small"
        type="primary"
        data-qa-selector-name="editButton"
        icon={<EditOutlined />}
        href={PROFIT_AND_LOSS_ITEM.LINK({ language, retailAdId })}
      />
    </PermissionChecker>
  );
};
