import { useCallback } from 'react';
import { map } from 'lodash/fp';

import { useLoadPresignedUrlsLazyQuery } from '~/apollo/gql-types';
import { PNL_IMPORT_BASE_OPTIONS } from '~/pages/Import/utils';

export const useUploadCredentials = () => {
  const [loadPreSignedUrls] = useLoadPresignedUrlsLazyQuery({
    ...PNL_IMPORT_BASE_OPTIONS
  });

  return useCallback(
    async (files) => {
      const entities = map(
        (file) => ({
          httpMethod: 'PUT',
          fileName: `${Date.now()}_${file.name}`
        }),
        files
      );

      return await loadPreSignedUrls({
        variables: {
          entities
        }
      });
    },
    [loadPreSignedUrls]
  );
};
