import { useCallback } from 'react';

import {
  PnlItemCreateProjectionInput,
  useCreatePnlItemMutation
} from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

export const useCreateItem = () => {
  const [createItem] = useCreatePnlItemMutation({
    ...REQUEST_BASE_OPTIONS,
    refetchQueries: ['RetailAdPnlData', 'PnlItemChangelog']
  });

  return useCallback(
    async (item: PnlItemCreateProjectionInput) => {
      await createItem({ variables: { item } });
    },
    [createItem]
  );
};
