import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AD_ITEM } from 'routes';

import { TableCellWrap } from '~/components/TableCellWrap';

import { Actions } from '../Actions';

import { IFormattedOverviewData } from './useOverviewProps';

const { Link } = Typography;

export function useOverviewColumns() {
  const { t } = useTranslation();
  const { language } = useParams();

  return useMemo(
    () =>
      [
        {
          title: t('bo.profitAndLoss.overview.table.vehicle'),
          dataIndex: 'title',
          render: (title) => (
            <TableCellWrap value={title} qaSelector="vehicle" />
          )
        },
        {
          title: t('bo.profitAndLoss.overview.table.retailCountry'),
          dataIndex: ['retailAd', 'country'],
          render: (retailCountry) => (
            <TableCellWrap value={retailCountry} qaSelector="retailCountry" />
          )
        },
        {
          title: t('bo.profitAndLoss.overview.table.stockNumber'),
          dataIndex: ['retailAd', 'stockNumber'],
          render: (stockNumber, { retailAd: { adId } }) => (
            <Link
              href={AD_ITEM.LINK({ language, adId })}
              data-qa-selector-name="stockNumber"
            >
              {stockNumber}
            </Link>
          )
        },
        {
          title: t('bo.profitAndLoss.overview.table.vin'),
          dataIndex: 'vin',
          render: (vin) => <TableCellWrap value={vin} qaSelector="vin" />
        },
        {
          title: t('bo.profitAndLoss.overview.table.signedContract'),
          dataIndex: 'contractSignedOn',
          render: (contractSignedOn) => (
            <TableCellWrap
              value={contractSignedOn}
              qaSelector="contractSignedOn"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.overview.table.carHandoverDate'),
          dataIndex: 'vehicleDeliveredOn',
          render: (vehicleDeliveredOn) => (
            <TableCellWrap
              value={vehicleDeliveredOn}
              qaSelector="vehicleDeliveredOn"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.overview.table.costNetTotal'),
          dataIndex: 'costTotal',
          render: (costTotal, { costItemCount }) => (
            <TableCellWrap
              value={`${costTotal} (${costItemCount})`}
              qaSelector="costTotal"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.overview.table.revenueNetTotal'),
          dataIndex: 'revenueTotal',
          render: (revenueTotal, { revenueItemCount }) => (
            <TableCellWrap
              value={`${revenueTotal} (${revenueItemCount})`}
              qaSelector="revenueTotal"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.overview.table.actions'),
          dataIndex: 'id',
          align: 'center',
          render: (id) => <Actions retailAdId={id} />
        }
      ] as ColumnsType<IFormattedOverviewData>,
    [language, t]
  );
}
