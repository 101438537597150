// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Axs6PFOSF_qROeGR4Hh7 {\n  padding: 0 15px 20px;\n}\n.d2qWfk2JS1gSZSTnc2nJ {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n}\n.d2qWfk2JS1gSZSTnc2nJ .C0GiUhNTAYZug7NalLFe {\n  display: flex;\n  align-items: center;\n  margin-bottom: 0;\n}\n.d2qWfk2JS1gSZSTnc2nJ .WMjmhBfNOPS6DNfxkNcU {\n  margin-right: 10px;\n}\n.d2qWfk2JS1gSZSTnc2nJ .FYh_IhFUbhlv4O_OQdCc {\n  text-transform: uppercase;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Layout/styles.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EAEA,mBAAA;AADF;AAJA;EAQI,aAAA;EACA,mBAAA;EACA,gBAAA;AADJ;AATA;EAcI,kBAAA;AAFJ;AAZA;EAkBI,yBAAA;EAEA,iBAAA;AAJJ","sourcesContent":[".layout {\n  padding: 0 15px 20px;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  margin-bottom: 12px;\n\n  .titleWrapper {\n    display: flex;\n    align-items: center;\n    margin-bottom: 0;\n  }\n\n  .iconWrapper {\n    margin-right: 10px;\n  }\n\n  .title {\n    text-transform: uppercase;\n\n    font-weight: bold;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "Axs6PFOSF_qROeGR4Hh7",
	"header": "d2qWfk2JS1gSZSTnc2nJ",
	"titleWrapper": "C0GiUhNTAYZug7NalLFe",
	"iconWrapper": "WMjmhBfNOPS6DNfxkNcU",
	"title": "FYh_IhFUbhlv4O_OQdCc"
};
export default ___CSS_LOADER_EXPORT___;
