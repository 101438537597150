import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { PROFIT_AND_LOSS } from 'routes';

import { PermissionChecker } from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { useModalContext } from '~/providers/ModalProvider/context';

import cn from './styles.less';

export const Actions = () => {
  const { t } = useTranslation();
  const { language } = useParams();
  const { openModal } = useModalContext();

  return (
    <div className={cn.buttonHolder}>
      <Button
        type="primary"
        data-qa-selector-name="goToList"
        href={PROFIT_AND_LOSS.LINK({ language })}
      >
        <span>{t('bo.profitAndLoss.buttons.goToList')}</span>
      </Button>
      <PermissionChecker
        domain={DOMAINS.PNL_MANAGEMENT}
        allow={PERMISSIONS.MUTATION_CREATE_PNL_ITEM}
      >
        <Button
          type="primary"
          data-qa-selector-name="addItem"
          icon={<PlusOutlined />}
          onClick={openModal}
        >
          <span>{t('bo.profitAndLoss.item.buttons.addItem')}</span>
        </Button>
      </PermissionChecker>
    </div>
  );
};
