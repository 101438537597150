import React, { ReactNode } from 'react';
import { Col, Row } from 'antd';

import cn from './styles.less';

export interface IInfoRowProps {
  value: ReactNode;
  label: ReactNode;
  className?: string;
}

export const InfoRow = ({ value, label, className }: IInfoRowProps) => (
  <Row className={className}>
    <Col className={cn.label} sm={6}>
      {label}
    </Col>
    <Col sm={18}>{value}</Col>
  </Row>
);
