import { get } from 'lodash/fp';
import { Tooltip, TooltipProps } from 'antd';
import React, { memo, useMemo } from 'react';
import * as Flags from '@retail/i18n/components/CountryFlags';

import { toLocalizedCountry } from '~/utils/i18n';

export type ICountryFlagProps = Omit<TooltipProps, 'overlay'> & {
  country: string;
  className?: string;
  style?: React.CSSProperties;
};

export const CountryFlag = memo<ICountryFlagProps>(
  ({ country, className, style, ...attr }) => {
    const FlagSvg = get([country], Flags);
    const title = useMemo(() => toLocalizedCountry(country), [country]);

    return (
      <>
        {FlagSvg ? (
          <Tooltip overlay={title} {...attr}>
            <FlagSvg className={className} style={style} />
          </Tooltip>
        ) : (
          title ?? 'N/A'
        )}
      </>
    );
  }
);
