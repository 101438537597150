import { Table, Space } from 'antd';

import { PaginationRow } from '~/components/PaginationRow';

import { useChangelogProps } from './hooks/useChangelogProps';
import cn from './styles.less';

export const ChangeLogTable = () => {
  const { dataSource, columns, isLoading, controller, onPageChange } =
    useChangelogProps();

  return (
    <Space direction="vertical" size="large" className={cn.fullWidth}>
      <PaginationRow
        onChange={onPageChange}
        current={controller.page}
        total={controller.totalPages}
        pageSize={controller.pageSize}
        isLoading={isLoading}
      />

      <Table
        rowKey="id"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        pagination={false}
        scroll={{ x: true }}
      />

      <PaginationRow
        onChange={onPageChange}
        current={controller.page}
        total={controller.totalPages}
        pageSize={controller.pageSize}
        isLoading={isLoading}
      />
    </Space>
  );
};
