import { find, flow, get } from 'lodash/fp';

import { PnlInvoiceType } from '~/apollo/gql-types';
import { TableCellWrap } from '~/components/TableCellWrap';
import { renderValue } from '~/helpers/common';
import { useInvoiceTypeOptions } from '~/hooks/options/useInvoiceTypeOptions';

interface Props {
  value: PnlInvoiceType;
}

export function InvoiceTypeCell({ value }: Props) {
  const invoiceTypeOptions = useInvoiceTypeOptions();

  return (
    <TableCellWrap
      value={renderValue(
        flow(find({ value }), get(['label']))(invoiceTypeOptions)
      )}
      qaSelector="pnlInvoiceType"
    />
  );
}
