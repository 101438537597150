import { createFilterPayload, SortType } from '@retail/gql-utils';

import { SearchProjection2Input } from '~/apollo/gql-types';

export interface IChangeLogParamsModel
  extends Pick<SearchProjection2Input, 'page' | 'pageSize'> {
  totalPages?: number;
}

const sorts = [
  {
    property: 'createdOn',
    direction: SortType.DESC
  }
];

export function getChangelogParams({
  page: rawPage,
  pageSize
}: IChangeLogParamsModel) {
  const page = Number(rawPage) - 1 || 0;

  return createFilterPayload({
    sorts,
    page,
    pageSize
  });
}
