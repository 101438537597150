import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PROFIT_AND_LOSS_PARTNERS } from 'routes';

import { MdFinanceIcon } from '~/components/Icons/MdFinanceIcon';
import { Layout, LayoutBody, LayoutHeader } from '~/components/Layout';
import { withPermissions } from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import { PartnersCreateForm } from './PartnersCreateForm';
import cn from './styles.less';

export const PartnersCreate = withPermissions(
  PERMISSIONS.MUTATION_CREATE_PNL_PARTNER,
  DOMAINS.PARTNERS_PNL_MANAGEMENT
)(() => {
  const { t } = useTranslation();
  const { language } = useParams();

  return (
    <Layout className={cn.wrapper}>
      <LayoutHeader
        title={t('bo.profitAndLoss.partners.buttons.addPartner')}
        icon={<MdFinanceIcon />}
      />
      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <div className={cn.buttonHolder}>
            <Button
              type="primary"
              href={PROFIT_AND_LOSS_PARTNERS.LINK({ language })}
            >
              {t('bo.profitAndLoss.buttons.goToList')}
            </Button>
          </div>

          <PartnersCreateForm />
        </Space>
      </LayoutBody>
    </Layout>
  );
});
