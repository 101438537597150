// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q4Lo27hzHJ6iiLSre47w {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Overview/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF","sourcesContent":[".fullWidth {\n  width: 100%;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "q4Lo27hzHJ6iiLSre47w"
};
export default ___CSS_LOADER_EXPORT___;
