import { some, flow, get, includes } from 'lodash/fp';
import {
  createFilter,
  createFilterPayload,
  FilterType,
  SortType
} from '@retail/gql-utils';

import {
  SearchProjection2Input,
  RetailDataImportState
} from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { DOMAINS } from '~/constants/permissions';

export const PNL_IMPORT_BASE_OPTIONS = {
  ...REQUEST_BASE_OPTIONS,
  context: {
    headers: { 'access-domain': DOMAINS.PNL_IMPORT_MANAGEMENT }
  }
};

export interface PnlParamsModel
  extends Pick<SearchProjection2Input, 'page' | 'pageSize'> {
  totalPages?: number;
}

const sorts = [
  {
    property: 'createdOn',
    direction: SortType.DESC
  }
];

export function getPnlImportParams({
  page: rawPage,
  pageSize
}: PnlParamsModel) {
  const page = Number(rawPage) - 1 || 0;
  const filter = createFilter('dataImportType', FilterType.EQUAL, 'PNL');

  return createFilterPayload({
    filter,
    sorts,
    page,
    pageSize
  });
}

export const getHasInProgressItems = some(
  flow(get(['state']), (state) =>
    includes(state, [
      RetailDataImportState.Created,
      RetailDataImportState.InProgress
    ])
  )
);
