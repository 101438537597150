import { ColProps, Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';

import { FIELDS_LABEL_COL_SPAN } from '~/constants/form';

import { FormItem } from './FormItem';

const { TextArea } = Input;

type Props<T> = {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  labelCol?: ColProps;
  required?: boolean;
  disabled?: boolean;
  qaSelector?: string;
} & TextAreaProps;

export function TextareaControlled<T extends FieldValues>({
  label,
  labelCol,
  controllerProps,
  required,
  disabled,
  qaSelector,
  ...restInputProps
}: Props<T>) {
  const { field } = useController(controllerProps);

  return (
    <FormItem
      label={label}
      labelCol={{ span: FIELDS_LABEL_COL_SPAN, ...labelCol }}
      required={required}
      disabled={disabled}
      controllerProps={controllerProps as unknown as UseControllerProps}
    >
      <TextArea
        {...field}
        {...restInputProps}
        data-qa-selector-name={qaSelector}
      />
    </FormItem>
  );
}
