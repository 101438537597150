import { currencies } from '@retail/currency/constants';
import { flow, isObject, map } from 'lodash/fp';
import { useMemo } from 'react';

const encodeName = (name) => {
  name = String(name).replace(/\./g, '###');

  return `_${name}`;
};

const prepareOptions = flow(
  map((value) => (isObject(value) ? value : { value })),
  map(({ value, label, ...props }: Record<string, unknown>) => {
    label = label || value;

    return { ...props, label, value, name: encodeName(value) };
  })
);

export function useCurrencyOptions() {
  return useMemo(() => prepareOptions(currencies), []);
}
