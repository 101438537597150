import { useMemo } from 'react';

import { useSearchItemTypesQuery } from '~/apollo/gql-types';
import { encodeName } from '~/helpers/fieldOptions/encodeName';

import {
  ITEM_TYPES_LIST_BASE_OPTIONS,
  IItemTypesListParams,
  getSearchParams
} from './utils';

export function useItemTypesListOptions(params: IItemTypesListParams = {}) {
  const {
    data,
    loading: isLoading,
    called
  } = useSearchItemTypesQuery({
    ...ITEM_TYPES_LIST_BASE_OPTIONS,
    variables: {
      search: getSearchParams(params)
    }
  });

  return useMemo(() => {
    const entities = data?.searchPnlItemTypes?.entities;
    const isLoaded = !isLoading && called;

    return {
      isLoading,
      isLoaded,
      options:
        entities?.map(({ id, name, type, category }) => ({
          type,
          category,
          value: id,
          label: name,
          name: encodeName(id)
        })) ?? []
    };
  }, [called, data, isLoading]);
}
