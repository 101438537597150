import { DeleteOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import {
  PnlItemTypeProjection,
  useDeleteItemTypeMutation
} from '~/apollo/gql-types';
import { useConfirmModal } from '~/hooks/useConfirmModal';
import { PermissionChecker } from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import { ITEM_TYPES_BASE_OPTIONS } from './utils';

interface Props {
  record: PnlItemTypeProjection;
}

export function Actions({ record }: Props) {
  const { t } = useTranslation();
  const openConfirmModal = useConfirmModal();
  const [removeItem, { loading }] = useDeleteItemTypeMutation({
    ...ITEM_TYPES_BASE_OPTIONS,
    refetchQueries: ['SearchItemTypes'],
    onCompleted: () =>
      notification.success({
        message: t('bo.profitAndLoss.partners.action.delete.success')
      })
  });

  const handleRemoveItem = useCallback(() => {
    openConfirmModal({
      content: t('bo.profitAndLoss.itemTypes.action.delete.confirm'),
      onConfirm: () => removeItem({ variables: { id: record.id } })
    });
  }, [openConfirmModal, record, removeItem, t]);

  return (
    <PermissionChecker
      domain={DOMAINS.ITEM_TYPES_PNL_MANAGEMENT}
      allow={PERMISSIONS.MUTATION_DELETE_PNL_ITEM_TYPE}
    >
      <Button
        size="small"
        type="primary"
        data-qa-selector-name="delete"
        danger
        loading={loading}
        icon={<DeleteOutlined />}
        onClick={handleRemoveItem}
      />
    </PermissionChecker>
  );
}
