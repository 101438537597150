import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  unitsFromNetMinorToGrossMinor,
  unitsFromNetMinorToVatAmountMinor
} from '~/helpers/currency';
import { TFormModalValues } from '~/pages/RetailAdItem/FormModal/utils';
import { useModalContext } from '~/providers/ModalProvider/context';

export interface IUseSyncFormValuesProps {
  initialValues: Partial<TFormModalValues>;
}

export interface IUseSyncFormValues {
  handleTypeChange: (value: string) => void;
  handleCategoryChange: (value: string) => void;
  handleNetAmountChange: (value: string) => void;
  handleVatRateChange: (value: string) => void;
}

export const useSyncFormValues = ({
  initialValues
}: IUseSyncFormValuesProps): IUseSyncFormValues => {
  const {
    modalState: { isOpen }
  } = useModalContext();
  const { setValue, getValues, reset } = useFormContext<TFormModalValues>();

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  useEffect(() => {
    //Reset values on modal close
    if (!isOpen) {
      reset({});
    }
  }, [reset, isOpen]);

  const clearCategory = useCallback(
    () => setValue('category', null),
    [setValue]
  );
  const clearPnlItemTypeId = useCallback(
    () => setValue('pnlItemTypeId', null),
    [setValue]
  );

  const handleAmountChange = useCallback(
    (netAmountMinorUnits, vatRate) => {
      const grossAmountMinorUnits = unitsFromNetMinorToGrossMinor(
        netAmountMinorUnits,
        vatRate
      );
      const vatAmountMinorUnits = unitsFromNetMinorToVatAmountMinor(
        netAmountMinorUnits,
        vatRate
      );

      setValue('grossAmountMinorUnits', grossAmountMinorUnits);
      setValue('vatAmountMinorUnits', vatAmountMinorUnits);
    },
    [setValue]
  );

  const handleNetAmountChange = useCallback(
    (netAmountMinorUnits) => {
      handleAmountChange(netAmountMinorUnits * 100, getValues('vatRate'));
    },
    [getValues, handleAmountChange]
  );

  const handleVatRateChange = useCallback(
    (vatRate) => {
      handleAmountChange(getValues('netAmountMinorUnits'), vatRate);
    },
    [getValues, handleAmountChange]
  );

  const handleCategoryChange = useCallback(() => {
    clearPnlItemTypeId();
  }, [clearPnlItemTypeId]);

  const handleTypeChange = useCallback(() => {
    clearCategory();
    clearPnlItemTypeId();
  }, [clearCategory, clearPnlItemTypeId]);

  return useMemo(
    () => ({
      handleCategoryChange,
      handleTypeChange,
      handleNetAmountChange,
      handleVatRateChange
    }),
    [
      handleCategoryChange,
      handleNetAmountChange,
      handleTypeChange,
      handleVatRateChange
    ]
  );
};
