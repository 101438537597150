import {
  createFilter,
  createFilterPayload,
  FilterType
} from '@retail/gql-utils';
import { useMemo } from 'react';

import {
  PnlPartnerProjection,
  useSearchPnlPartnersQuery
} from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { DOMAINS } from '~/constants/permissions';
import { mapPartnerToOption } from '~/utils/partner';

const DEFAULT_FILTER = createFilter('deleted', FilterType.EQUAL, false);

export const PARTNERS_BASE_OPTIONS = {
  ...REQUEST_BASE_OPTIONS,
  context: {
    headers: { 'access-domain': DOMAINS.PNL_MANAGEMENT }
  }
};

export function usePnlPartnersOptions(partner: PnlPartnerProjection) {
  const { data, loading: isLoading } = useSearchPnlPartnersQuery({
    ...PARTNERS_BASE_OPTIONS,
    skip: !partner?.id,
    variables: {
      search: createFilterPayload({
        filter: createFilter(null, FilterType.AND, [
          DEFAULT_FILTER,
          createFilter('id', FilterType.EQUAL, partner?.id)
        ]),
        page: 0
      })
    }
  });

  const options = useMemo(() => {
    const entities =
      !partner || data?.data?.entities.some((x) => x.id === partner.id)
        ? data?.data?.entities
        : data?.data?.entities.concat(partner);

    return entities?.map(mapPartnerToOption) ?? [];
  }, [data?.data?.entities, partner]);

  return {
    options,
    isLoading
  };
}
