import { ApolloError } from '@apollo/client/errors';
import { notification } from 'antd';

export const REQUEST_BASE_OPTIONS = {
  onError: (error: ApolloError) => {
    notification.warning({
      message: 'Error',
      description: error?.graphQLErrors?.[0]?.message
    });
  }
};
