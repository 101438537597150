import { PnlPartnerProjection } from '~/apollo/gql-types';
import { encodeName } from '~/helpers/fieldOptions/encodeName';

export function mapPartnerToOption({
  id,
  partnerId,
  partnerName
}: PnlPartnerProjection) {
  return {
    label: `${partnerId} - ${partnerName}`,
    value: id,
    name: encodeName(id)
  };
}
