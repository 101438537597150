import React, { ReactElement, ReactNode } from 'react';

import { useModal } from '~/hooks/useModal';

import { Provider } from './context';

export interface IModalProviderProps {
  children: ReactNode;
  modalComponent: ReactElement;
}

export const ModalProvider = ({
  children,
  modalComponent
}: IModalProviderProps): ReactElement => {
  const modal = useModal();

  return (
    <Provider value={modal}>
      {children}

      {modalComponent}
    </Provider>
  );
};

export default ModalProvider;
