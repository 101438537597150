import React, { ReactNode } from 'react';
import cns from 'classnames';

import cn from './styles.less';

export interface ILayoutProps {
  children: ReactNode;
  className?: string;
}

export const Layout = ({ children, className }: ILayoutProps) => {
  return (
    <div className={cns(cn.layout, className)}>
      <div>{children}</div>
    </div>
  );
};
