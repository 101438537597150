/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ColProps, Select, SelectProps } from 'antd';
import { OptionProps } from 'antd/es/select';
import { ReactChild, ReactNode } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';

import { FIELDS_LABEL_COL_SPAN } from '~/constants/form';

import { FormItem } from './FormItem';

export interface AntOptionProps
  extends Partial<OptionProps>,
    Pick<Required<OptionProps>, 'value'> {
  label: ReactChild;
}

const { Option } = Select;

const defaultGetOptionValue = ({ value }: AntOptionProps) => value;
const defaultGetOptionLabel = ({ label }: AntOptionProps) => label;
const defaultChecker = (value) => value;
const defaultFilterOption = (input, option) =>
  option.children.toLowerCase().includes(input.toLowerCase());
const getOnChangeValue = (value) => value ?? null;

export interface SelectControlledProps<T> extends Omit<SelectProps, 'options'> {
  controllerProps: UseControllerProps<T>;
  labelCol?: ColProps;
  label?: ReactNode;
  options: Array<AntOptionProps>;
  required?: boolean;
  qaSelector?: string;
  getOptionValue?: (item: AntOptionProps) => string;
  getOptionLabel?: (item: AntOptionProps) => ReactChild;
  checker?: (value, options) => any;
}

export function SelectControlled<T extends FieldValues>({
  label,
  options,
  labelCol,
  controllerProps,
  filterOption = defaultFilterOption,
  required,
  disabled,
  checker = defaultChecker,
  getOptionValue = defaultGetOptionValue,
  getOptionLabel = defaultGetOptionLabel,
  onChange,
  className,
  qaSelector,
  ...attr
}: SelectControlledProps<T>) {
  const { field } = useController(controllerProps);

  return (
    <FormItem
      label={label}
      labelCol={labelCol || { span: FIELDS_LABEL_COL_SPAN }}
      required={required}
      disabled={disabled}
      className={className}
      controllerProps={controllerProps as unknown as UseControllerProps}
    >
      <Select
        {...field}
        disabled={disabled}
        filterOption={filterOption}
        value={checker(field.value, options)}
        data-qa-selector-name={qaSelector}
        dropdownClassName={`${qaSelector}-dropdown`}
        onChange={(value, ...rest) => {
          field.onChange(getOnChangeValue(value));
          onChange?.(getOnChangeValue(value), ...rest);
        }}
        {...attr}
      >
        {options?.map((item) => (
          <Option
            {...item}
            key={getOptionValue(item)!}
            value={getOptionValue(item)}
            data-qa-selector="option"
            data-qa-selector-option-value={getOptionValue(item)}
            data-qa-selector-option-label={getOptionLabel(item)}
          >
            {getOptionLabel(item)}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
}
