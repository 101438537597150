enum CATEGORIES {
  PURCHASING = 'Purchasing',
  REFURBISHMENT = 'Refurbishment',
  LOGISTICS_COMPOUND_SERVICES = 'Logistics - Compound Services',
  LOGISTICS_TRANSPORT = 'Logistics - Transport',
  SALES = 'Sales',
  CLAIMS = 'Claims'
}

export const DEFAULT_CATEGORIES = [
  CATEGORIES.PURCHASING,
  CATEGORIES.REFURBISHMENT,
  CATEGORIES.LOGISTICS_COMPOUND_SERVICES,
  CATEGORIES.LOGISTICS_TRANSPORT,
  CATEGORIES.SALES,
  CATEGORIES.CLAIMS
];
