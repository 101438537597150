// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cSkYiDS50AgX8VdeefQP {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  grid-gap: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/RetailAdItem/Actions/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;AACF","sourcesContent":[".buttonHolder {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  grid-gap: 1.5rem;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonHolder": "cSkYiDS50AgX8VdeefQP"
};
export default ___CSS_LOADER_EXPORT___;
