/* eslint-disable no-magic-numbers */
import { flow, values, flatten, uniq } from 'lodash/fp';
import { COUNTRIES } from '@retail/i18n/constants';

export const VAT_RATES = {
  [COUNTRIES.AT]: [0, 0.2],
  [COUNTRIES.BE]: [0, 0.21],
  [COUNTRIES.DE]: [0, 0.19],
  // RNB-930: this VAT rate needed for a limited time in DE
  [`${COUNTRIES.DE}_2`]: [0, 0.16],
  [COUNTRIES.ES]: [0, 0.21],
  [COUNTRIES.FR]: [0, 0.2],
  [COUNTRIES.IT]: [0, 0.22],
  [COUNTRIES.NL]: [0, 0.21],
  [COUNTRIES.PL]: [0, 0.23],
  [COUNTRIES.SE]: [0, 0.25]
};

export const getVatRates = (country?) => {
  if (!country) {
    return flow(values, flatten, uniq, (values = []) => values.sort())(
      VAT_RATES
    );
  }

  return VAT_RATES[country];
};
