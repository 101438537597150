import {
  createFilter,
  createFilterPayload,
  createSort,
  FilterType,
  SortType,
  Filter,
  Sort
} from '@retail/gql-utils';

import { SearchProjection2Input } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { DOMAINS } from '~/constants/permissions';

import { ItemTypeSearchModel } from './SearchForm';

export const ITEM_TYPES_BASE_OPTIONS = {
  ...REQUEST_BASE_OPTIONS,
  context: {
    headers: { 'access-domain': DOMAINS.ITEM_TYPES_PNL_MANAGEMENT }
  }
};

export interface ItemTypeParamsModel
  extends Partial<ItemTypeSearchModel>,
    Pick<SearchProjection2Input, 'page' | 'pageSize'> {
  totalPages?: number;
}

export function getItemTypesParams({
  page: rawPage,
  pageSize,
  category,
  sortBy,
  type
}: ItemTypeParamsModel) {
  const page = Number(rawPage) - 1 || 0;
  const filtersAnd = [];

  filtersAnd.push(createFilter('deleted', FilterType.EQUAL, false));

  if (category?.length) {
    filtersAnd.push(createFilter('category', FilterType.IN, category));
  }

  if (type) {
    filtersAnd.push(createFilter('type', FilterType.EQUAL, type));
  }

  let filters: Filter[] = [];

  if (filtersAnd.length) {
    filters = filters.concat(
      filtersAnd.length > 1
        ? createFilter(null, FilterType.AND, filtersAnd)
        : filtersAnd[0]
    );
  }

  let filter: Filter = null;

  if (filters.length) {
    filter =
      filters.length > 1
        ? createFilter(null, FilterType.AND, filters)
        : filters[0];
  }

  const sorts: Sort[] = [createSort(sortBy ?? 'createdOn', SortType.DESC)];

  return createFilterPayload({
    filter,
    sorts,
    page,
    pageSize
  });
}
