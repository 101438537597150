import { notification } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import { ANTD_EXTERNAL_VERSION_LESS } from '~/constants/antd';

import Root from './root.component';

if (__WEBPACK_DEV_SERVER__) {
  require('antd/dist/antd.less');
  /**
   * TODO: antd/Datepicker doesn't add prefix for animation classes.
   * Importint pure css fixes it. The issue reproduces only in standalone mode.
   */
  require('antd/lib/date-picker/style/css');
}

notification.config({
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`
});

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  }
});

export const { bootstrap, mount, unmount } = lifecycles;
