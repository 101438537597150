import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PnlInvoiceType } from '~/apollo/gql-types';

export const OPTIONS = [
  {
    label: `bo.profitAndLoss.invoiceType.${PnlInvoiceType.CreditNote}`,
    value: PnlInvoiceType.CreditNote
  },
  {
    value: PnlInvoiceType.SalesCreditNote,
    label: `bo.profitAndLoss.invoiceType.${PnlInvoiceType.SalesCreditNote}`
  },
  {
    label: `bo.profitAndLoss.invoiceType.${PnlInvoiceType.Estimate}`,
    value: PnlInvoiceType.Estimate
  },
  {
    label: `bo.profitAndLoss.invoiceType.${PnlInvoiceType.Invoice}`,
    value: PnlInvoiceType.Invoice
  }
];

export function useInvoiceTypeOptions() {
  const { t } = useTranslation();

  return useMemo(() => OPTIONS.map((x) => ({ ...x, label: t(x.label) })), [t]);
}
