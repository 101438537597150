// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NLUtXkDZqu77A5ATG8Vx {\n  cursor: pointer;\n  transition: border-color 0.3s;\n  text-align: center;\n  border: 1px dashed #d5d5d5;\n  border-radius: 4px;\n  outline: 0;\n  background: #f7f7f7;\n  padding: 12px;\n}\n.NLUtXkDZqu77A5ATG8Vx.yyQv1qdvSbVCt2IrWndQ {\n  border: 2px dashed #6eb5f2;\n}\n.NLUtXkDZqu77A5ATG8Vx .RajwZ5PTnpdZUbkx7kHw {\n  color: rgba(0, 0, 0, 0.85);\n  font-size: 16px;\n  margin-bottom: 5px;\n}\n.NLUtXkDZqu77A5ATG8Vx .rIkE_KXwhDSffubWNsle {\n  color: rgba(0, 0, 0, 0.45);\n  font-size: 14px;\n}\n.psDHf5DB6pPNHqtmix2N {\n  margin-bottom: 8px;\n  display: block;\n  line-height: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Form/DropzoneControlled/styles.less"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,6BAAA;EACA,kBAAA;EACA,0BAAA;EACA,kBAAA;EACA,UAAA;EACA,mBAAA;EACA,aAAA;AAHF;AAKE;EACE,0BAAA;AAHJ;AARA;EAeI,0BAAA;EAEA,eAAA;EACA,kBAAA;AALJ;AAbA;EAsBI,0BAAA;EACA,eAAA;AANJ;AAUA;EACE,kBAAA;EACA,cAAA;EACA,iBAAA;AARF","sourcesContent":["@gray-medium-lighter: #d5d5d5;\n@gray-lighter: #f7f7f7;\n@blue-lighter: #6eb5f2;\n\n.dropzone {\n  cursor: pointer;\n  transition: border-color 0.3s;\n  text-align: center;\n  border: 1px dashed @gray-medium-lighter;\n  border-radius: 4px;\n  outline: 0;\n  background: @gray-lighter;\n  padding: 12px;\n\n  &.active {\n    border: 2px dashed @blue-lighter;\n  }\n\n  .placeholder {\n    color: rgba(0, 0, 0, 0.85);\n\n    font-size: 16px;\n    margin-bottom: 5px;\n  }\n\n  .hint {\n    color: rgba(0, 0, 0, 0.45);\n    font-size: 14px;\n  }\n}\n\n.label {\n  margin-bottom: 8px;\n  display: block;\n  line-height: 32px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": "NLUtXkDZqu77A5ATG8Vx",
	"active": "yyQv1qdvSbVCt2IrWndQ",
	"placeholder": "RajwZ5PTnpdZUbkx7kHw",
	"hint": "rIkE_KXwhDSffubWNsle",
	"label": "psDHf5DB6pPNHqtmix2N"
};
export default ___CSS_LOADER_EXPORT___;
