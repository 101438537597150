import {
  createFilter,
  createFilterPayload,
  createSort,
  FilterType,
  SortType
} from '@retail/gql-utils';

import { SearchProjection2Input } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { DOMAINS } from '~/constants/permissions';

import { PartnersSearchModel } from './SearchForm';

export const PARTNERS_BASE_OPTIONS = {
  ...REQUEST_BASE_OPTIONS,
  context: {
    headers: { 'access-domain': DOMAINS.PARTNERS_PNL_MANAGEMENT }
  }
};

export const DEFAULT_SORTING_FIELD = 'createdOn';
export const DEFAULT_SORTING = {
  direction: SortType.DESC,
  property: DEFAULT_SORTING_FIELD
};

export interface PartnersParamsModel
  extends Partial<PartnersSearchModel>,
    Pick<SearchProjection2Input, 'page' | 'pageSize'> {
  totalPages?: number;
}

export function getPartnersParams({
  page: rawPage,
  pageSize,
  country,
  partnerId,
  partnerVatId,
  partnerName,
  sortBy
}: PartnersParamsModel) {
  const page = Number(rawPage) - 1 || 0;
  const filters = [];

  filters.push(createFilter('deleted', FilterType.EQUAL, false));

  if (country?.length > 0) {
    filters.push(createFilter('country', FilterType.IN, country));
  }

  if (partnerId) {
    filters.push(createFilter('partnerId', FilterType.LIKE, partnerId));
  }

  if (partnerVatId) {
    filters.push(createFilter('partnerVatId', FilterType.LIKE, partnerVatId));
  }

  if (partnerName) {
    filters.push(createFilter('partnerName', FilterType.LIKE, partnerName));
  }

  let filter = null;

  if (filters.length) {
    filter =
      filters.length > 1
        ? createFilter(null, FilterType.AND, filters)
        : filters[0];
  }

  let sorts;

  if (!sortBy || sortBy === DEFAULT_SORTING_FIELD) {
    sorts = [DEFAULT_SORTING];
  } else {
    sorts = [createSort(sortBy, SortType.ASC), DEFAULT_SORTING];
  }

  return createFilterPayload({
    filter,
    sorts,
    page,
    pageSize
  });
}
