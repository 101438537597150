import { useSearchParamsController } from '@retail/hooks';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo } from 'react';
import { PROFIT_AND_LOSS_IMPORT } from 'routes';

import {
  RetailDataImportProjection,
  useSearchRetailDataImportQuery
} from '~/apollo/gql-types';

import {
  getHasInProgressItems,
  getPnlImportParams,
  PnlParamsModel,
  PNL_IMPORT_BASE_OPTIONS
} from '../utils';

import { useImportColumns } from './useImportColumns';

export interface IUseOverviewPropsResult {
  controller: PnlParamsModel;
  onPageChange: (value, pageSize) => null;
  columns: ColumnsType<RetailDataImportProjection>;
  dataSource: RetailDataImportProjection;
  isLoading: boolean;
}

export const useImportProps = () => {
  const columns = useImportColumns();
  const { controller, onPageChange, updateController } =
    useSearchParamsController<PnlParamsModel>(
      ({ queryString, language, navigate }) =>
        navigate(`${PROFIT_AND_LOSS_IMPORT.LINK({ language })}${queryString}`)
    );
  const {
    data,
    loading: isLoading,
    startPolling,
    stopPolling
  } = useSearchRetailDataImportQuery({
    ...PNL_IMPORT_BASE_OPTIONS,
    variables: {
      search: getPnlImportParams(controller)
    },
    onCompleted: (data) =>
      updateController({
        totalPages: data?.data?.totalEntityCount
      })
  });

  useEffect(() => {
    const hasInProgressItems = getHasInProgressItems(data?.data?.entities);
    stopPolling();

    startPolling(5000);

    if (!hasInProgressItems) {
      stopPolling();
    }

    return () => stopPolling();
  }, [data, startPolling, stopPolling]);

  const dataSource = useMemo(() => {
    const rawDataSource = data?.data?.entities ?? [];

    if (!rawDataSource.length) {
      return [];
    }

    return rawDataSource;
  }, [data]);

  return useMemo(
    () => ({
      dataSource,
      columns,
      isLoading,
      controller,
      onPageChange
    }),
    [columns, controller, dataSource, isLoading, onPageChange]
  );
};
