// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BYRCaf4GNuM6iyxj9ql_ .antd-4-21-7-input-number,\n.BYRCaf4GNuM6iyxj9ql_ .antd-4-21-7-picker {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/RetailAdItem/FormModal/FormFields/styles.less"],"names":[],"mappings":"AAKM;;EAEE,WAAA;AAJR","sourcesContent":["@import '../../../../styles/constants.less';\n\n.form {\n  :global {\n    .@{antd-version} {\n      &-input-number,\n      &-picker {\n        width: 100%;\n      }\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "BYRCaf4GNuM6iyxj9ql_"
};
export default ___CSS_LOADER_EXPORT___;
