import { DeleteOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import {
  PnlPartnerProjection,
  useDeletePnlPartnerMutation
} from '~/apollo/gql-types';
import { useConfirmModal } from '~/hooks/useConfirmModal';
import { PermissionChecker } from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import { PARTNERS_BASE_OPTIONS } from './utils';

interface Props {
  id: PnlPartnerProjection['id'];
}

export function Actions({ id }: Props) {
  const { t } = useTranslation();
  const openConfirmModal = useConfirmModal();
  const [removePartner, { loading }] = useDeletePnlPartnerMutation({
    ...PARTNERS_BASE_OPTIONS,
    refetchQueries: ['SearchPnlPartners'],
    onCompleted: () =>
      notification.success({
        message: t('bo.profitAndLoss.partners.action.delete.success')
      })
  });

  const handleRemovePartner = useCallback(() => {
    openConfirmModal({
      content: t('bo.profitAndLoss.partners.action.delete.confirm'),
      onConfirm: () => removePartner({ variables: { id } })
    });
  }, [id, openConfirmModal, removePartner, t]);

  return (
    <PermissionChecker
      domain={DOMAINS.PARTNERS_PNL_MANAGEMENT}
      allow={PERMISSIONS.MUTATION_DELETE_PNL_PARTNER}
    >
      <Button
        size="small"
        type="primary"
        data-qa-selector-name="deletePartner"
        danger
        loading={loading}
        icon={<DeleteOutlined />}
        onClick={handleRemovePartner}
      />
    </PermissionChecker>
  );
}
