import {
  createFilter,
  createFilterPayload,
  FilterType,
  SortType
} from '@retail/gql-utils';

import { SearchProjection2Input } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { DOMAINS } from '~/constants/permissions';
import { PNL_ITEM_TYPES } from '~/constants/pnl';

export const ITEM_TYPES_LIST_BASE_OPTIONS = {
  ...REQUEST_BASE_OPTIONS,
  context: {
    headers: { 'access-domain': DOMAINS.ITEM_TYPES_PNL_MANAGEMENT }
  }
};

export const DEFAULT_SORTING_FIELD = 'createdOn';
export const DEFAULT_SORTING = {
  direction: SortType.DESC,
  property: DEFAULT_SORTING_FIELD
};

export interface IItemTypesListParams {
  type?: PNL_ITEM_TYPES;
  category?: string;
}

export interface PartnersParamsModel
  extends Partial<IItemTypesListParams>,
    Pick<SearchProjection2Input, 'page' | 'pageSize'> {
  totalPages?: number;
}

export function getSearchParams({
  page: rawPage,
  pageSize,
  type,
  category
}: PartnersParamsModel) {
  const page = Number(rawPage) - 1 || 0;
  const filters = [];

  filters.push(createFilter('deleted', FilterType.EQUAL, false));

  if (type) {
    filters.push(createFilter('type', FilterType.EQUAL, type));
  }
  if (category) {
    filters.push(createFilter('category', FilterType.EQUAL, category));
  }

  let filter = null;

  if (filters.length) {
    filter =
      filters.length > 1
        ? createFilter(null, FilterType.AND, filters)
        : filters[0];
  }
  const sorts = [DEFAULT_SORTING];

  return createFilterPayload({
    filter,
    sorts,
    page,
    pageSize
  });
}
