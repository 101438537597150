import { formatCurrency } from '@retail/backoffice-ui';
import {
  DEFAULT_CONVERSION_MAJOR,
  calculateSumByCurrency,
  getConversionMajorByCurrencyCode,
  getCurrencyCodeFromPrice,
  unitsFromGrossToNet,
  unitsFromMajorToMinor,
  unitsFromMinorToMajor,
  unitsFromNetToGross
} from '@retail/currency';
import {
  flow,
  get,
  isEmpty,
  join,
  map,
  round,
  toString,
  uniq,
  without
} from 'lodash/fp';

export const getFormatInputFromMinorUnits = (currencyCode) => (value) => {
  if (value == null) {
    return '';
  }
  const conversionMajor = getConversionMajorByCurrencyCode(currencyCode);

  return unitsFromMinorToMajor(value, conversionMajor);
};

export const getParseInputToMinorUnits = (currencyCode) => (value) => {
  if (value == null) {
    return '';
  }
  const conversionMajor = getConversionMajorByCurrencyCode(currencyCode);

  return unitsFromMajorToMinor(value, conversionMajor);
};

export const safeExtractPriceMajorUnits = (price) => {
  if (!price || price.amountMinorUnits == null) {
    return '';
  }

  const currencyCode = getCurrencyCodeFromPrice(price);
  const conversionMajor =
    price.conversionMajor || getConversionMajorByCurrencyCode(currencyCode);

  return unitsFromMinorToMajor(price.amountMinorUnits, conversionMajor);
};

export const unitsFromNetMinorToGrossMajor =
  (netMinorUnits, vatRate, currencyCode) => () => {
    const conversionMajor = getConversionMajorByCurrencyCode(currencyCode);
    const grossMinorUnits = unitsFromNetToGross(netMinorUnits, vatRate);
    const grossMajorUnits = unitsFromMinorToMajor(
      grossMinorUnits,
      conversionMajor
    );

    return grossMajorUnits === undefined ? '' : grossMajorUnits;
  };

export const unitsFromGrossMinorToNetMajor =
  (grossMinorUnits, vatRate, currencyCode) => () => {
    const conversionMajor = getConversionMajorByCurrencyCode(currencyCode);
    const netMinorUnits = unitsFromGrossToNet(grossMinorUnits, vatRate);
    const netMajorUnits = unitsFromMinorToMajor(netMinorUnits, conversionMajor);

    return netMajorUnits === undefined ? '' : netMajorUnits;
  };

export const unitsFromNetMinorToVatAmountMinor = (netMinorUnits, vatRate) =>
  round((netMinorUnits || 0) * (vatRate || 0));

export const unitsFromNetMinorToGrossMinor = (
  netMinorUnits?,
  vatRate?,
  taxAdvantageMinorUnits?
) => {
  const netMinorUnitsCalculated =
    (netMinorUnits || 0) + (taxAdvantageMinorUnits || 0);

  const grossMinorUnits = unitsFromNetToGross(
    netMinorUnitsCalculated,
    vatRate || 0
  );

  return round(grossMinorUnits || 0);
};

export const formatNetMinorUnits =
  (grossMinorUnits, taxAdvantageMinorUnits, vatRate, currencyCode) => () => {
    const grossMinorUnitsCalculated = taxAdvantageMinorUnits
      ? grossMinorUnits - taxAdvantageMinorUnits
      : grossMinorUnits;

    const conversionMajor =
      getConversionMajorByCurrencyCode(currencyCode) ||
      DEFAULT_CONVERSION_MAJOR;

    const netMinorUnits = unitsFromGrossToNet(
      grossMinorUnitsCalculated,
      vatRate
    );
    const netMajorUnits = unitsFromMinorToMajor(netMinorUnits, conversionMajor);

    return netMajorUnits === undefined
      ? ''
      : Math.ceil(netMajorUnits * conversionMajor) / conversionMajor;
  };

export const getFormattedCurrencySum = (baseCurrencyCode, prices) => {
  const currencyCodes = flow(map(get('currencyCode')), uniq)(prices);
  const differentCurrencyCodes = without([baseCurrencyCode], currencyCodes);

  if (!isEmpty(differentCurrencyCodes)) {
    return join('+', currencyCodes);
  }

  return flow(
    calculateSumByCurrency,
    formatCurrency,
    toString
  )(baseCurrencyCode, prices);
};
