import { ControlledPositiveIntegerInput } from '@retail/backoffice-ui';
import { Button, Card, Col, Form, notification, Row } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PROFIT_AND_LOSS_PARTNERS } from 'routes';

import {
  PnlPartnerCreateProjectionInput,
  useCreatePnlPartnerMutation
} from '~/apollo/gql-types';
import { InputControlled } from '~/components/Form/InputControlled';
import { SelectControlled } from '~/components/Form/SelectControlled';
import { useCountriesOptions } from '~/hooks/options/useCountriesOptions';
import { useCurrencyOptions } from '~/hooks/options/useCurrencyOptions';

import { PARTNERS_BASE_OPTIONS } from '../Partners/utils';

import { usePartnerCountryOptions } from './hooks/usePartnerCountryOptions';

export function PartnersCreateForm() {
  const { t } = useTranslation();
  const { language } = useParams();
  const navigate = useNavigate();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<PnlPartnerCreateProjectionInput>();

  const [createPartner] = useCreatePnlPartnerMutation({
    ...PARTNERS_BASE_OPTIONS,
    onCompleted() {
      notification.success({
        message: t('bo.profitAndLoss.partnersCreate.form.success.message')
      });
      navigate(PROFIT_AND_LOSS_PARTNERS.LINK({ language }));
    }
  });

  async function onSubmit(data: PnlPartnerCreateProjectionInput) {
    await createPartner({ variables: { data } });
  }

  const countryOptions = useCountriesOptions();
  const partnerCountryOptions = usePartnerCountryOptions();
  const currencyOptions = useCurrencyOptions();

  return (
    <Card title={t('bo.profitAndLoss.partnersCreate.form.title')}>
      <Form onFinish={handleSubmit(onSubmit)}>
        <Row gutter={24}>
          <Col sm={12}>
            <SelectControlled
              showSearch
              options={countryOptions}
              qaSelector="country"
              label={t('bo.profitAndLoss.partnersCreate.form.country.label')}
              required
              controllerProps={{
                control,
                name: 'country'
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={12}>
            <InputControlled
              qaSelector="partnerName"
              label={t(
                'bo.profitAndLoss.partnersCreate.form.parnterName.label'
              )}
              required
              controllerProps={{
                control,
                name: 'partnerName'
              }}
            />
          </Col>
          <Col sm={12}>
            <InputControlled
              qaSelector="partnerVatId"
              label={t(
                'bo.profitAndLoss.partnersCreate.form.partnerVatID.label'
              )}
              required
              controllerProps={{
                control,
                name: 'partnerVatId'
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={12}>
            <InputControlled
              qaSelector="partnerAddress"
              label={t(
                'bo.profitAndLoss.partnersCreate.form.partnerAddress.label'
              )}
              required
              controllerProps={{
                control,
                name: 'partnerAddress'
              }}
            />
          </Col>
          <Col sm={12}>
            <InputControlled
              qaSelector="partnerCity"
              label={t(
                'bo.profitAndLoss.partnersCreate.form.partnerCity.label'
              )}
              required
              controllerProps={{
                control,
                name: 'partnerCity'
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={12}>
            <InputControlled
              qaSelector="partnerZip"
              label={t('bo.profitAndLoss.partnersCreate.form.partnerZIP.label')}
              required
              controllerProps={{
                control,
                name: 'partnerZip'
              }}
            />
          </Col>
          <Col sm={12}>
            <SelectControlled
              showSearch
              qaSelector="partnerCountry"
              options={partnerCountryOptions}
              label={t(
                'bo.profitAndLoss.partnersCreate.form.partnerCountry.label'
              )}
              required
              controllerProps={{
                control,
                name: 'partnerCountry'
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={12}>
            <ControlledPositiveIntegerInput
              data-qa-selector="adminCarrierID"
              label={t(
                'bo.profitAndLoss.partnersCreate.form.adminCarrierID.label'
              )}
              parse={parseInt}
              controllerProps={{
                control,
                name: 'adminCarrierId'
              }}
            />
          </Col>
          <Col sm={12}>
            <SelectControlled
              showSearch
              qaSelector="invoiceCurrency"
              options={currencyOptions}
              label={t(
                'bo.profitAndLoss.partnersCreate.form.invoiceCurrency.label'
              )}
              required
              controllerProps={{
                control,
                name: 'invoiceCurrency'
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <InputControlled
              qaSelector="auto1Entity"
              label={t(
                'bo.profitAndLoss.partnersCreate.form.auto1Entity.label'
              )}
              controllerProps={{
                control,
                name: 'auto1Entity'
              }}
            />
          </Col>
        </Row>
        <Row justify="end" gutter={24}>
          <Col>
            <Button
              type="ghost"
              data-qa-selector-name="reset"
              onClick={() => reset()}
            >
              {t('bo.profitAndLoss.buttons.reset')}
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              data-qa-selector-name="submit"
              disabled={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('bo.profitAndLoss.buttons.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
