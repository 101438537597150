import { useMemo } from 'react';

import { useUser } from '~/hooks/useUser';
import { getUserAccessibleCountryCodeOptions } from '~/helpers/user/userAccessibleCountryCodeOptions';

export interface ICountryOption {
  value: string;
  label: string;
}

export function useCountriesOptions() {
  const { accessParameters } = useUser();
  return useMemo(
    (): Array<ICountryOption> =>
      getUserAccessibleCountryCodeOptions(
        accessParameters
      ) as unknown as Array<ICountryOption>,
    [accessParameters]
  );
}
