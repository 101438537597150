import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const OPTIONS = [
  { label: 'bo.profitAndLoss.itemTypes.table.createdOn', value: 'createdOn' },
  { label: 'bo.profitAndLoss.itemTypes.table.itemName', value: 'name' }
];

export function useSortByOptions() {
  const { t } = useTranslation();

  return useMemo(() => OPTIONS.map((x) => ({ ...x, label: t(x.label) })), [t]);
}
