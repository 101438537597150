import { Modal, ModalProps } from 'antd';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PnlItemProjection } from '~/apollo/gql-types';
import { useModalContext } from '~/providers/ModalProvider/context';

import { IUseRetailAdItemDataResult } from '../hooks/useItemData';

import { FormFields } from './FormFields';
import { useFormSubmit } from './hooks/useFormSubmit';
import { useInitialValues } from './hooks/useInitialValues';
import { TFormModalValues } from './utils';

export interface IFormModalProps {
  retailAdData: IUseRetailAdItemDataResult['data'];
}

export const FormModal = ({ retailAdData }: IFormModalProps) => {
  const { t } = useTranslation();
  const { modalState, closeModal } = useModalContext<PnlItemProjection>();
  const { data, isOpen } = modalState;
  const initialValues = useInitialValues(retailAdData, data);
  const onFormSubmit = useFormSubmit();
  const title = initialValues?.id
    ? t('bo.profitAndLoss.item.editItemForm.title')
    : t('bo.profitAndLoss.item.addItemForm.title');

  const form = useForm<TFormModalValues>();
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  const modalProps = useMemo(
    (): Partial<ModalProps> => ({
      onCancel: closeModal as VoidFunction,
      cancelButtonProps: {
        onClick: closeModal as VoidFunction,
        className: 'modal-cancelButton',
        children: t('bo.profitAndLoss.buttons.cancel')
      },
      okButtonProps: {
        htmlType: 'submit',
        disabled: isSubmitting,
        onClick: handleSubmit(onFormSubmit),
        className: 'modal-submitButton',
        children: t('bo.profitAndLoss.buttons.save')
      }
    }),
    [closeModal, handleSubmit, isSubmitting, onFormSubmit, t]
  );

  return (
    <Modal title={title} width={800} visible={isOpen} {...modalProps}>
      <FormProvider {...form}>
        <FormFields
          partner={data?.partner}
          initialValues={initialValues}
          onSubmit={onFormSubmit}
        />
      </FormProvider>
    </Modal>
  );
};
