// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hj6vjecyGLyOt2jgr5Yp {\n  width: 100%;\n}\n.X9BkwXTlqhicqk0mDJp8 {\n  margin-bottom: 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Import/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAEA;EACE,mBAAA;AAAF","sourcesContent":[".fullWidth {\n  width: 100%;\n}\n\n.layoutHeader {\n  margin-bottom: 36px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "hj6vjecyGLyOt2jgr5Yp",
	"layoutHeader": "X9BkwXTlqhicqk0mDJp8"
};
export default ___CSS_LOADER_EXPORT___;
