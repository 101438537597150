import { formatCurrency } from '@retail/backoffice-ui';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PnlItemProjection } from '~/apollo/gql-types';
import { TableCellWrap } from '~/components/TableCellWrap';
import { renderValue } from '~/helpers/common';
import { formatDateTime } from '~/helpers/date';
import { formatVatRateLabel } from '~/helpers/fieldOptions/vatRate';

import { Actions } from './Actions';
import { InvoiceTypeCell } from './InvoiceTypeCell';

export interface IUseColumnsProps {
  onEdit: (data: PnlItemProjection) => void;
  onDelete: (id: string) => void;
}

export const useColumns = ({ onEdit, onDelete }: IUseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          title: t('bo.profitAndLoss.item.costsRevenue.table.itemName'),
          dataIndex: ['type', 'name'],
          render: (value) => (
            <TableCellWrap value={renderValue(value)} qaSelector="itemName" />
          )
        },
        {
          title: t('bo.profitAndLoss.item.costsRevenue.table.invoiceType'),
          dataIndex: 'pnlInvoiceType',
          render: (pnlInvoiceType) => <InvoiceTypeCell value={pnlInvoiceType} />
        },
        {
          title: t('bo.profitAndLoss.item.costsRevenue.table.netAmount'),
          dataIndex: 'netAmount',
          render: (netAmount) => (
            <TableCellWrap
              value={renderValue(formatCurrency(netAmount))}
              qaSelector="netAmount"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.item.costsRevenue.table.vatAmount'),
          dataIndex: 'vatAmount',
          render: (vatAmount) => (
            <TableCellWrap
              value={renderValue(formatCurrency(vatAmount))}
              qaSelector="vatAmount"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.item.costsRevenue.table.grossAmount'),
          dataIndex: 'grossAmount',
          render: (grossAmount) => (
            <TableCellWrap
              value={renderValue(formatCurrency(grossAmount))}
              qaSelector="grossAmount"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.item.costsRevenue.table.vatRate'),
          dataIndex: 'vatRate',
          render: (vatRate) => (
            <TableCellWrap
              value={renderValue(formatVatRateLabel(vatRate))}
              qaSelector="vatRate"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.item.costsRevenue.table.invoiceNumber'),
          dataIndex: 'invoiceNumber',
          render: (invoiceNumber) => (
            <TableCellWrap
              value={renderValue(invoiceNumber)}
              qaSelector="invoiceNumber"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.item.costsRevenue.table.invoiceDate'),
          dataIndex: 'invoiceDate',
          render: (invoiceDate) => (
            <TableCellWrap
              value={renderValue(formatDateTime(invoiceDate))}
              qaSelector="invoiceDate"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.item.costsRevenue.table.action'),
          dataIndex: 'id',
          align: 'center',
          render: (id, data) => (
            <Actions id={id} onDelete={onDelete} onEdit={() => onEdit(data)} />
          )
        }
      ] as ColumnsType<PnlItemProjection>,
    [onDelete, onEdit, t]
  );
};
