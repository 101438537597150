import { useCallback } from 'react';
import { notification } from 'antd';
import { omit } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { useModalContext } from '~/providers/ModalProvider/context';
import {
  PnlItemCreateProjectionInput,
  PnlItemUpdateProjectionInput
} from '~/apollo/gql-types';

import {
  OMIT_FIELDS_PNL_ITEM_CREATE,
  OMIT_FIELDS_PNL_ITEM_UPDATE
} from '../utils';

import { useCreateItem } from './useCreateItem';
import { useUpdateItem } from './useUpdateItem';

notification.config({
  top: 55
});

export const useFormSubmit = () => {
  const { t } = useTranslation();
  const { closeModal } = useModalContext();
  const createItem = useCreateItem();
  const editItem = useUpdateItem();

  return useCallback(
    async (values) => {
      try {
        const { id } = values;
        const omitFields = id
          ? OMIT_FIELDS_PNL_ITEM_UPDATE
          : OMIT_FIELDS_PNL_ITEM_CREATE;
        const item = omit(omitFields)(values);

        if (id) {
          await editItem(id, item as PnlItemUpdateProjectionInput);
          notification.success({
            message: t(
              'bo.profitAndLoss.item.addItemForm.notification.updateSuccess'
            )
          });
        } else {
          await createItem(item as PnlItemCreateProjectionInput);
          notification.success({
            message: t(
              'bo.profitAndLoss.item.addItemForm.notification.createSuccess'
            )
          });
        }
      } catch (e) {
        notification.error({
          message: t('bo.profitAndLoss.item.addItemForm.notification.error')
        });
      } finally {
        closeModal();
      }
    },
    [closeModal, createItem, editItem, t]
  );
};
