import { Table } from 'antd';
import { useCallback } from 'react';

import { PnlItemProjection } from '~/apollo/gql-types';
import { useModalContext } from '~/providers/ModalProvider/context';

import { IUseColumnsProps, useColumns } from './useColumns';

export interface ICostRevenueTableProps
  extends Omit<IUseColumnsProps, 'onEdit'> {
  data: PnlItemProjection[];
  isLoading: boolean;
}

export function CostRevenueTable({
  data,
  isLoading,
  onDelete
}: ICostRevenueTableProps) {
  const { openModal } = useModalContext();
  const onEdit = useCallback(
    (data: PnlItemProjection) => openModal(data),
    [openModal]
  );
  const columns = useColumns({
    onEdit,
    onDelete
  });

  return (
    <>
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
        scroll={{ x: true }}
      />
    </>
  );
}
