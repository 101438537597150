import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Form } from 'antd';
import { Dispatch } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectControlled } from '~/components/Form/SelectControlled';
import { useItemTypeOptions } from '~/hooks/options/useItemTypeOptions';
import { useItemTypeCategoryOptions } from '~/hooks/options/useItemTypeCategoryOptions';

import { useSortByOptions } from '../hooks/useSortByOptions';

import cn from './style.less';

export interface ItemTypeSearchModel {
  category?: string[];
  sortBy?: string;
  type?: string;
}

interface Props extends ItemTypeSearchModel {
  onSubmit: Dispatch<ItemTypeSearchModel>;
  loading: boolean;
}

export function SearchForm({
  category,
  sortBy,
  type,
  loading,
  onSubmit
}: Props) {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<ItemTypeSearchModel>({
    defaultValues: {
      category,
      sortBy,
      type
    }
  });
  const { options: categoryOptions, isLoading: categoriesLoading } =
    useItemTypeCategoryOptions();

  const sortByOptions = useSortByOptions();
  const typeOptions = useItemTypeOptions();

  return (
    <Card
      title={t('bo.profitAndLoss.itemTypes.searchForm.title')}
      loading={loading}
    >
      <Form
        disabled={categoriesLoading}
        onFinish={handleSubmit(onSubmit)}
        data-qa-selector-name="searchForm"
        className={cn.form}
      >
        <SelectControlled
          allowClear
          options={categoryOptions}
          qaSelector="searchForm-category"
          label={t('bo.profitAndLoss.itemTypes.searchForm.category.label')}
          placeholder={t(
            'bo.profitAndLoss.itemTypes.searchForm.category.placeholder'
          )}
          mode="multiple"
          loading={categoriesLoading}
          controllerProps={{
            control,
            name: 'category'
          }}
        />

        <SelectControlled
          allowClear
          options={typeOptions}
          qaSelector="searchForm-type"
          label={t('bo.profitAndLoss.itemTypes.searchForm.type.label')}
          placeholder={t(
            'bo.profitAndLoss.itemTypes.searchForm.type.placeholder'
          )}
          controllerProps={{
            control,
            name: 'type'
          }}
        />

        <SelectControlled
          allowClear
          options={sortByOptions}
          qaSelector="searchForm-sortBy"
          label={t('bo.profitAndLoss.itemTypes.searchForm.sortBy.label')}
          placeholder={t(
            'bo.profitAndLoss.itemTypes.searchForm.sortBy.placeholder'
          )}
          controllerProps={{
            control,
            name: 'sortBy'
          }}
        />

        <Button
          type="primary"
          htmlType="submit"
          data-qa-selector-name="searchButton"
          className={cn.submit}
          icon={<SearchOutlined />}
        />
      </Form>
    </Card>
  );
}
