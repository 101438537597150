import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeletePnlItemMutation } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { useConfirmModal } from '~/hooks/useConfirmModal';

import { IUseRetailAdItemDataResult } from '../../hooks/useItemData';
import { CostRevenueTable } from '../CostRevenueTable';

export interface ICostsTableProps {
  data: IUseRetailAdItemDataResult['data']['costItems'];
  isLoading: boolean;
}

export const CostsTable = ({ data, isLoading }: ICostsTableProps) => {
  const { t } = useTranslation();
  const openConfirmModal = useConfirmModal();
  const [removeCost] = useDeletePnlItemMutation({
    ...REQUEST_BASE_OPTIONS,
    refetchQueries: ['RetailAdPnlData'],
    onCompleted: () =>
      notification.success({
        message: t('bo.profitAndLoss.item.addItemForm.notification.deleted')
      })
  });

  const handleRemoveCost = useCallback(
    (id: string) => {
      openConfirmModal({
        content: t('bo.profitAndLoss.item.costsRevenue.action.delete.confirm'),
        onConfirm: () => removeCost({ variables: { id } })
      });
    },
    [openConfirmModal, removeCost, t]
  );

  return (
    <CostRevenueTable
      data={data}
      onDelete={handleRemoveCost}
      isLoading={isLoading}
    />
  );
};
