import React, { ReactNode } from 'react';

export interface IBodyProps {
  children: ReactNode;
  className?: string;
}

export const Body = ({ children, ...rest }: IBodyProps) => {
  return <div {...rest}>{children}</div>;
};
