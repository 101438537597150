import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const OPTIONS = [
  {
    label: 'bo.profitAndLoss.partners.searchForm.sortBy.options.createdOn',
    value: 'createdOn'
  },
  {
    label: 'bo.profitAndLoss.partners.searchForm.sortBy.options.partnerName',
    value: 'partnerName'
  },
  {
    label: 'bo.profitAndLoss.partners.searchForm.sortBy.options.partnerId',
    value: 'partnerId'
  }
];

export function useSortByOptions() {
  const { t } = useTranslation();

  return useMemo(() => OPTIONS.map((x) => ({ ...x, label: t(x.label) })), [t]);
}
