import { Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ADMIN_USER } from 'routes';

import {
  RetailDataImportProjection,
  RetailDataImportState
} from '~/apollo/gql-types';
import { TableCellWrap } from '~/components/TableCellWrap';
import { renderValue } from '~/helpers/common';
import { formatDateTime } from '~/helpers/date';

import { Actions } from '../Actions';

const { Link } = Typography;

const getStatusColor = (state: RetailDataImportState) => {
  if (state === RetailDataImportState.Completed) {
    return 'green';
  }

  if (state === RetailDataImportState.Failed) {
    return 'red';
  }

  return 'blue';
};

export function useImportColumns() {
  const { t } = useTranslation();
  const { language } = useParams();

  return useMemo(
    () =>
      [
        {
          title: t('bo.profitAndLoss.imports.column.creationDate'),
          dataIndex: 'createdOn',
          render: (createdOn) => (
            <TableCellWrap
              value={formatDateTime(createdOn)}
              qaSelector="createdOn"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.imports.column.createdBy'),
          dataIndex: 'createdBy',
          render: ({ firstName, lastName, id }) => (
            <Link
              href={ADMIN_USER.LINK({ language, id })}
              data-qa-selector-name="createdBy"
            >
              {`${firstName} ${lastName}`}
            </Link>
          )
        },
        {
          title: t('bo.profitAndLoss.imports.column.source'),
          dataIndex: 'creationSource',
          render: (creationSource) => (
            <TableCellWrap
              value={renderValue(creationSource)}
              qaSelector="creationSource"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.imports.column.errorRows'),
          dataIndex: 'errorRows',
          render: (errorRows) => (
            <TableCellWrap value={errorRows} qaSelector="errorRows" />
          )
        },
        {
          title: t('bo.profitAndLoss.imports.column.successRows'),
          dataIndex: 'successRows',
          render: (successRows) => (
            <TableCellWrap value={successRows} qaSelector="successRows" />
          )
        },
        {
          title: t('bo.profitAndLoss.imports.column.status'),
          dataIndex: 'state',
          render: (state) => (
            <Tag color={getStatusColor(state)} data-qa-selector-name="state">
              {t(`bo.profitAndLoss.imports.status.${state}`)}
            </Tag>
          )
        },
        {
          title: t('bo.profitAndLoss.imports.column.actions'),
          dataIndex: 'id',
          render: (_, { resourceLocation, errorsResourceLocation }) => (
            <Actions
              resourceLocation={resourceLocation}
              errorsResourceLocation={errorsResourceLocation}
            />
          )
        }
      ] as ColumnsType<RetailDataImportProjection>,
    [language, t]
  );
}
