import { useEffect, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Modal, ModalProps } from 'antd';

import {
  PnlItemTypeCreateProjectionInput,
  useCreateItemTypeMutation
} from '~/apollo/gql-types';
import { InputControlled } from '~/components/Form/InputControlled';
import { SelectControlled } from '~/components/Form/SelectControlled';
import { SelectCreatable } from '~/components/Form/SelectCreatable';
import { TextareaControlled } from '~/components/Form/TextareaControlled';
import { IUseModal } from '~/hooks/useModal';
import { useItemTypeOptions } from '~/hooks/options/useItemTypeOptions';
import { useItemTypeCategoryOptions } from '~/hooks/options/useItemTypeCategoryOptions';

import { ITEM_TYPES_BASE_OPTIONS } from './utils';

interface ICreateItemTypeFormProps {
  isOpenModal: boolean;
  closeModal: IUseModal['closeModal'];
}

export function CreateItemTypeForm({
  isOpenModal,
  closeModal
}: ICreateItemTypeFormProps) {
  const { t } = useTranslation();
  const { options: categoriesOptions, isLoading: categoriesOptionsLoading } =
    useItemTypeCategoryOptions();
  const typeOptions = useItemTypeOptions();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = useForm<PnlItemTypeCreateProjectionInput>();
  const [createItemType] = useCreateItemTypeMutation({
    ...ITEM_TYPES_BASE_OPTIONS,
    refetchQueries: ['SearchItemTypes']
  });

  const onSubmit = useCallback(
    async (data: PnlItemTypeCreateProjectionInput) => {
      await createItemType({ variables: { data } });
      closeModal();
    },
    [closeModal, createItemType]
  );

  const modalProps = useMemo(
    (): Partial<ModalProps> => ({
      onCancel: closeModal,
      cancelButtonProps: {
        onClick: closeModal,
        className: 'modal-cancelButton',
        children: t('bo.profitAndLoss.buttons.cancel')
      },
      okButtonProps: {
        htmlType: 'submit',
        disabled: isSubmitting,
        onClick: handleSubmit(onSubmit),
        className: 'modal-submitButton',
        children: t('bo.profitAndLoss.buttons.save')
      }
    }),
    [closeModal, handleSubmit, isSubmitting, onSubmit, t]
  );

  useEffect(() => isOpenModal && reset(), [isOpenModal, reset]);

  return (
    <Modal visible={isOpenModal} onCancel={closeModal} {...modalProps}>
      <Form onFinish={handleSubmit(onSubmit)}>
        <SelectCreatable
          showSearch
          options={categoriesOptions}
          loading={categoriesOptionsLoading}
          label={t('bo.profitAndLoss.itemTypes.searchForm.category.label')}
          required
          controllerProps={{
            name: 'category',
            control
          }}
        />
        <InputControlled
          required
          label={t('bo.profitAndLoss.itemTypes.table.itemName')}
          controllerProps={{
            name: 'name',
            control
          }}
        />
        <SelectControlled
          required
          options={typeOptions}
          label={t('bo.profitAndLoss.itemTypes.table.itemType')}
          controllerProps={{
            name: 'type',
            control
          }}
        />
        <TextareaControlled
          label={t('bo.profitAndLoss.itemTypes.table.description')}
          rows={5}
          controllerProps={{
            name: 'description',
            control
          }}
        />
      </Form>
    </Modal>
  );
}
