import { ColProps } from 'antd';
import generatePicker, {
  PickerDateProps
} from 'antd/lib/date-picker/generatePicker';
import { isValid } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { ReactNode, useCallback, useMemo } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';

import { FIELDS_LABEL_COL_SPAN } from '~/constants/form';

import { FormItem } from './FormItem';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

type InputControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  dateFormat: string;
  qaSelector?: string;
  label?: ReactNode;
  labelCol?: ColProps;
  required?: boolean;
  disabled?: boolean;
} & PickerDateProps<Date>;

export function DatePickerControlled<T extends FieldValues>({
  required,
  disabled,
  label,
  labelCol,
  controllerProps,
  dateFormat,
  qaSelector,
  ...restInputProps
}: InputControlledProps<T>) {
  const { field } = useController(controllerProps);
  const date = useMemo(
    () =>
      field.value && isValid(new Date(field.value))
        ? new Date(field.value)
        : null,
    [field.value]
  );
  const handleChange = useCallback((date) => field.onChange(date), [field]);

  return (
    <FormItem
      label={label}
      labelCol={{ span: FIELDS_LABEL_COL_SPAN, ...labelCol }}
      required={required}
      disabled={disabled}
      controllerProps={controllerProps as unknown as UseControllerProps}
    >
      <DatePicker
        {...field}
        value={date}
        format={dateFormat}
        onChange={handleChange}
        popupStyle={{ zIndex: 9999 }}
        data-qa-selector-name={qaSelector}
        dropdownClassName={`${qaSelector}-dropdown`}
        {...restInputProps}
      />
    </FormItem>
  );
}
