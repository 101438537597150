import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PnlPartnerProjection } from '~/apollo/gql-types';
import { TableCellWrap } from '~/components/TableCellWrap';
import { formatDateTime } from '~/helpers/date';

import { Actions } from '../Actions';

export function usePartnersColumns() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          title: t('bo.profitAndLoss.partners.table.country'),
          dataIndex: 'country',
          render: (country) => (
            <TableCellWrap value={country} qaSelector="country" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.id'),
          dataIndex: 'partnerId',
          render: (partnerId) => (
            <TableCellWrap value={partnerId} qaSelector="partnerId" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.name'),
          dataIndex: 'partnerName',
          render: (partnerName) => (
            <TableCellWrap value={partnerName} qaSelector="partnerName" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.vatID'),
          dataIndex: 'partnerVatId',
          render: (partnerVatId) => (
            <TableCellWrap value={partnerVatId} qaSelector="partnerVatId" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.address'),
          dataIndex: 'partnerAddress',
          render: (partnerAddress) => (
            <TableCellWrap value={partnerAddress} qaSelector="partnerAddress" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.city'),
          dataIndex: 'partnerCity',
          render: (partnerCity) => (
            <TableCellWrap value={partnerCity} qaSelector="partnerCity" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.zip'),
          dataIndex: 'partnerZip',
          render: (partnerZip) => (
            <TableCellWrap value={partnerZip} qaSelector="partnerZip" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.partnerCountry'),
          dataIndex: 'partnerCountry',
          render: (partnerCountry) => (
            <TableCellWrap value={partnerCountry} qaSelector="partnerCountry" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.adminCarrierID'),
          dataIndex: 'adminCarrierId',
          render: (adminCarrierId) => (
            <TableCellWrap value={adminCarrierId} qaSelector="adminCarrierId" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.invoiceCurrency'),
          dataIndex: 'invoiceCurrency',
          render: (invoiceCurrency) => (
            <TableCellWrap
              value={invoiceCurrency}
              qaSelector="invoiceCurrency"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.auto1Entity'),
          dataIndex: 'auto1Entity',
          render: (auto1Entity) => (
            <TableCellWrap value={auto1Entity} qaSelector="auto1Entity" />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.createdOn'),
          dataIndex: 'createdOn',
          render: (createdOn) => (
            <TableCellWrap
              value={formatDateTime(createdOn)}
              qaSelector="createdOn"
            />
          )
        },
        {
          title: t('bo.profitAndLoss.partners.table.actions'),
          align: 'center',
          render: (_, { id }) => <Actions id={id} />
        }
      ] as ColumnsType<PnlPartnerProjection>,
    [t]
  );
}
