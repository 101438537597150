import { Button, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloudUploadOutlined } from '@ant-design/icons';

import { MdFinanceIcon } from '~/components/Icons/MdFinanceIcon';
import { Layout, LayoutBody, LayoutHeader } from '~/components/Layout';
import { PaginationRow } from '~/components/PaginationRow';
import {
  PermissionChecker,
  withPermissions
} from '~/components/PermissionChecker';
import { DOMAINS } from '~/constants/permissions/domains';
import { PERMISSIONS } from '~/constants/permissions/permissions';
import { useModal } from '~/hooks/useModal';

import { UploadModalForm } from './UploadModalForm';
import { useImportProps } from './hooks/useImportProps';
import cn from './styles.less';

export const Import = withPermissions(
  PERMISSIONS.QUERY_SEARCH_RETAIL_DATA_IMPORT,
  DOMAINS.PNL_IMPORT_MANAGEMENT
)(() => {
  const { t } = useTranslation();
  const {
    modalState: { isOpen },
    openModal,
    closeModal
  } = useModal();
  const { controller, columns, dataSource, isLoading, onPageChange } =
    useImportProps();

  return (
    <Layout>
      <LayoutHeader
        title={t('bo.profitAndLoss.import.title')}
        icon={<MdFinanceIcon />}
        className={cn.layoutHeader}
      >
        <PermissionChecker
          domain={DOMAINS.PNL_IMPORT_MANAGEMENT}
          allow={PERMISSIONS.MUTATION_IMPORT_PROFIT_AND_LOSS_ITEMS}
        >
          <Button
            type="primary"
            data-qa-selector-name="uploadCSV"
            icon={<CloudUploadOutlined />}
            onClick={openModal}
          >
            <span>{t('bo.profitAndLoss.import.button.uploadCSV')}</span>
          </Button>
        </PermissionChecker>
      </LayoutHeader>

      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <PaginationRow
            showTotalResults
            isLoading={isLoading}
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
          />
          <Table
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            loading={isLoading}
            pagination={false}
          />
          <PaginationRow
            isLoading={isLoading}
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
          />
        </Space>
        <UploadModalForm isOpenModal={isOpen} closeModal={closeModal} />
      </LayoutBody>
    </Layout>
  );
});
