import { PlusOutlined } from '@ant-design/icons';
import { useSearchParamsController } from '@retail/hooks';
import { Button, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { PROFIT_AND_LOSS_ITEM_TYPES } from 'routes';

import { useSearchItemTypesQuery } from '~/apollo/gql-types';
import { MdFinanceIcon } from '~/components/Icons/MdFinanceIcon';
import { Layout, LayoutBody, LayoutHeader } from '~/components/Layout';
import { PaginationRow } from '~/components/PaginationRow';
import {
  PermissionChecker,
  withPermissions
} from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';
import { useModal } from '~/hooks/useModal';

import { NavigationTabs } from '../NavigationTabs';

import { CreateItemTypeForm } from './CreateItemType';
import { useItemTypesColumns } from './hooks/useItemTypesColumns';
import { SearchForm } from './SearchForm';
import cn from './styles.less';
import {
  getItemTypesParams,
  ItemTypeParamsModel,
  ITEM_TYPES_BASE_OPTIONS
} from './utils';

export const ItemTypes = withPermissions(
  PERMISSIONS.QUERY_SEARCH_PNL_ITEM_TYPES,
  DOMAINS.ITEM_TYPES_PNL_MANAGEMENT
)(() => {
  const { t } = useTranslation();
  const columns = useItemTypesColumns();
  const { controller, onPageChange, updateController } =
    useSearchParamsController<ItemTypeParamsModel>(
      ({ queryString, language, navigate }) =>
        navigate(
          `${PROFIT_AND_LOSS_ITEM_TYPES.LINK({ language })}${queryString}`
        )
    );
  const {
    modalState: { isOpen },
    openModal,
    closeModal
  } = useModal();

  const { data, loading } = useSearchItemTypesQuery({
    ...ITEM_TYPES_BASE_OPTIONS,
    variables: {
      search: getItemTypesParams(controller)
    },
    onCompleted: (x) =>
      updateController({
        totalPages: x?.searchPnlItemTypes?.totalEntityCount
      })
  });
  const dataSource = data?.searchPnlItemTypes?.entities ?? [];

  return (
    <Layout>
      <LayoutHeader
        title={t('bo.profitAndLoss.itemTypes.title')}
        icon={<MdFinanceIcon />}
      >
        <PermissionChecker
          domain={DOMAINS.ITEM_TYPES_PNL_MANAGEMENT}
          allow={PERMISSIONS.MUTATION_CREATE_PNL_ITEM_TYPE}
        >
          <Button type="primary" onClick={openModal} icon={<PlusOutlined />}>
            <span>{t('bo.profitAndLoss.itemTypes.buttons.addItem')}</span>
          </Button>
        </PermissionChecker>
      </LayoutHeader>

      <NavigationTabs />

      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <SearchForm
            category={controller.category}
            sortBy={controller.sortBy}
            type={controller.type}
            loading={false}
            onSubmit={updateController}
          />
          <PaginationRow
            showTotalResults
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
            isLoading={loading}
          />
          <Table
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            pagination={false}
          />
          <PaginationRow
            onChange={onPageChange}
            current={controller.page}
            total={controller.totalPages}
            pageSize={controller.pageSize}
            isLoading={loading}
          />
          <CreateItemTypeForm isOpenModal={isOpen} closeModal={closeModal} />
        </Space>
      </LayoutBody>
    </Layout>
  );
});
